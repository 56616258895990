import { Injectable } from "@angular/core";
import { MessageService, PrimeNGConfig } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ToastrMessageService {
  constructor(
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig
  ) {
    this.primengConfig.ripple = true;
  }

  showSuccess(message:string = "Message Content") {
    console.log("showSuccess")
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: message
    });
  }

  showInfo(message:string = "Message Content") {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      life: 2000,
      detail: message,
    });
  }

  showWarn(message:string = "Message Content") {
    this.messageService.add({
      severity: "warn",
      summary: "Warn",
      detail: message,
    });
  }

  showError(message:string = "Message Content") {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }
}
