import { NgModule } from "@angular/core";
import { SithecTranslateModule } from "../../../../../e-commerce-common/src/lib/components/translate/sithec-translate.module";
import { ProductsRoutingModule } from "./products-routing.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
  ],
    imports: [
        SithecTranslateModule,
        ProductsRoutingModule
    ],
    exports: [
        ProductsRoutingModule
    ]
})

export class ProductsModule {}