import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@common/lib/services/user.service';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.less']
})
export class MenuAdminComponent implements OnInit {

  name?:String
  mail?:String

  constructor(
    private userService:UserService,
    private router:Router
  ) { }

  ngOnInit(): void {
    console.log(this.userService.user.name)
    if(this.userService.user.name != ""){
      localStorage.setItem('nombre', this.userService.user.name + " " + this.userService.user.lastName)
      this.name = this.userService.user.name + " " + this.userService.user.lastName
      this.mail = this.userService.user.username
    }

    if(localStorage.getItem('nombre') && localStorage.getItem('correo')){
      this.name = localStorage.getItem('nombre')!
      this.mail = localStorage.getItem('correo')!
    }/* else{
      localStorage.setItem('nombre', this.userService.user.name + " " + this.userService.user.lastName)
      localStorage.setItem('correo', this.userService.user.username)
      this.name = this.userService.user.name + " " + this.userService.user.lastName
      this.mail = this.userService.user.username
    } */
  }

  activateOption(route:string){
    return this.router.url.includes(route);
  }
}
