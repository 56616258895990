<div class="contenido">
    <div class="header-title">
        <p class="view-information">Banners > Catálogo de banners</p>
    </div>
    <div class="recuadro">
        <div class="filtros">
            <input class="name-field" type="text" placeholder="Buscar" [formControl]="search">
            <button class="create-button" [routerLink]="['/main-page/banners/crear']">Nuevo Banner</button>
        </div>
        <app-table [data]="bannersData" [columns]="columns" (onClick)="tableButton($event)"></app-table>
        <div class="paginator">
            <app-paginator 
                [totalItems]="totalItems" 
                [currentIndex]="currentIndex" 
                [sizePage]="sizePage"
                (changeCurrentIndex)="changeCurrentIndex($event)">
            </app-paginator>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" [ngClass]="{'show d-block':showConfirmDelete}">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="ajuste-detalle">
                    <h5 class="modal-title">Confirmación de baja</h5>
                    <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="closeModalDelete()">
                </div>
            </div>
            <div class="modal-body">
                <button class="cancel" (click)="closeModalDelete()">Cancelar</button>
                <button class="delete" (click)="confirmDelete()">Confirmar</button>
            </div>
        </div>
    </div>
</div>