import { Component, OnInit } from '@angular/core';
import { IPurchaseData, IPurchaseDetail } from '@common/lib/models/purchase.model'; 
import { IFileInformation } from "@common/lib/models/file.model";
import { IDeleteInformation } from '@common/lib/models/delete.model';
import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { SpinnerService } from "@common/lib/services/spinner.service";
import { ToastrMessageService } from "@common/lib/services/message.service";
import { debounceTime, finalize } from "rxjs/operators";
import { IErrorDefinition } from "@common/lib/models/error-definition.model";
import { FormGroup } from '@angular/forms';
import { Filter, FlagFilter } from '@common/lib/classes/filter'; 
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { IFileRequest } from '@common/lib/models/file-request';
import { SithecCommonApiService } from '@common/lib/services/sithec-common-api.service';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.less']
})
export class VentasComponent implements OnInit {

  formSubscription!:Subscription
  filtros!: Filter 
  banderaFiltros!: FlagFilter

  loadingPDF = false
  loadingXML = false
  loadingCertificate = false

  mostrarSelector: boolean = false;
  mostrarDetalles: boolean = false;
  mostrarCarga: boolean = false;
  mostrarWarning: boolean = false;

  file : any
  format: string = ""
  fileName: string = ""
  PurchaseId: string = ""
  url:string = ""

  purchasesData:IPurchaseData[]=[];
  purchaseDetail:IPurchaseDetail[]=[]
  purchaseData!: IPurchaseData 

  nombre: String = "";
  rfc: String= "";
  fecha: String= "";
  tipoArchivo: String = "";
  message: String = "";

  facturadoXML:boolean=false
  facturadoPDF:boolean=false

  monthly: boolean = false;
  months: number = 0;
  comission: number = 0;
  bank: string = ""
  subtotal: number = 0;
  descuento: number = 0;
  iva: number = 0;
  total: number = 0;

  desloseVisible:boolean=false;
  desgloseMensaje:string="Ver Desglose";

  detallesProductoVisible:boolean=false;
  desgloseCompraVisible:boolean=false;
  detallesProductoMensaje:string="Mostrar detalles de producto";
  desgloseCompra:string="Mostrar desglose de Compra";
  editable = false;
  columns = [
    /* {
      title: 'ID', 
      fieldName: 'id',
      type: 'text',
      tagClass: 'id'
    }, */
    {
      title: 'Folio',
      fieldName: 'folio',
      type: 'number',
      tagClass: ''
    },
    {
      title: 'Fecha', 
      fieldName: 'date',
      type: 'date',
      tagClass: 'date'
    },
    {
      title: 'Nombre',
      fieldName: 'user',
      type: 'user',
      tagClass: 'name'
    },
    {
      title: 'Productos',
      fieldName: 'details',
      type: 'list',
      tagClass: ''
    },
    {
      title: 'Método de Pago',
      fieldName: 'paymentMethod',
      type: 'paymentMethod',
      tagClass: 'text'
    },
    {
      title: 'Certificado',
      fieldName: 'certificado',
      type: 'tag',
      tagClass: 'certificado'
    },
    {
      title: 'PDF',
      fieldName: 'facturadoPDF',
      type: 'tag',
      tagClass: 'pdf'
    },
    {
      title: 'XML',
      fieldName: 'facturadoXML',
      type: 'tag',
      tagClass: 'xml'
    },
    {
      title: 'Acciones',
      fieldName: 'detalles',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/details.svg',
          customClass: 'icon',
          actionId: 1,
          type: 'image',
        }
      ]
    },
  ]

  formularioFiltros: FormGroup = new FormGroup(
    {
      search: new FormControl(''),
      initialDate: new FormControl(''), 
      finalDate: new FormControl(''), 
      todo: new FormControl(true),
      certificado: new FormControl(''),
      pdf: new FormControl(''),
      xml: new FormControl(''),
    }
  );

  constructor(
    private apiService:AdminApiServiceService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService, 
    private sithecCommonApiService:SithecCommonApiService,
    private searchService:GenericSearchService,
    private toastr: ToastrService
    ) {}

  ngOnInit(): void {
    this.filtros = new Filter() 
    this.banderaFiltros = new Filter() 

    this.banderaFiltros["todo"]=true
    this.banderaFiltros["pdf"]=false
    this.banderaFiltros["xml"]=false
    this.banderaFiltros["certificado"]=false

    this.filtros["search"]=""
    this.filtros["todo"]=true
    this.filtros["pdf"]=false
    this.filtros["xml"]=false
    this.filtros["certificado"]=false
    
    this.spinnerService.show()

    this.apiService
      .getPurchases()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (purchases: any) => {
            this.purchasesData=purchases.results
        },
        ({ message }: IErrorDefinition) => this.toastrService.showError(message)
      ); 
  }

  ngAfterViewInit(){
    this.formSubscription=this.formularioFiltros.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.filtros=data

      const finalDate: Date = new Date(this.filtros.finalDate);
      const initialDate: Date = new Date(this.filtros.initialDate);

      // Comparar las fechas
      console.log(this.filtros)
      if(this.filtros.finalDate && this.filtros.initialDate)
        if (finalDate < initialDate) {
          this.toastr.warning('Estas intentando poner una fecha final menor a la fecha inicial','Advertencia');
          this.formularioFiltros.controls['finalDate'].setValue(this.filtros.initialDate)
          this.filtros.finalDate = this.filtros.initialDate
        }
      this.filterPurchases()
    })
  } 
  ngOnDestroy(): void {
    if(this.formSubscription){
      this.formSubscription.unsubscribe()
    }
    
  }

  onInputChange(event: any) {
    // this.searchService.setSearchValue(this.filtros);
  }

  async tableButton(event:any){
    this.purchaseData = event.object
    this.purchaseDetail = event.object.details
    this.purchaseDetail.forEach(item => item.effectiveDate = item.effectiveDate?.split("T")?.[0])
    console.log(this.purchaseData)
    switch(event.btn.actionId){
      case 1:
        this.mostrarDetalles = true;
        this.nombre = this.purchaseData.user.name;
        this.rfc = this.purchaseData.user.rfc;
        this.fecha = this.purchaseData.date;
        this.PurchaseId=this.purchaseData.id;
        this.facturadoPDF=this.purchaseData.facturadoPDF;
        this.facturadoXML=this.purchaseData.facturadoXML;

        this.iva=this.purchaseData.iva
        this.total=this.purchaseData.total
        this.descuento=this.purchaseData.discount
        this.subtotal = (this.purchaseData.total-this.purchaseData.iva)+this.purchaseData.discount;
        this.monthly=this.purchaseData.toMonths
        this.months=this.purchaseData.months
        this.comission=this.purchaseData.commission
        this.bank=this.purchaseData.bank
        break;
    }
  }
  async showDetails(event:IPurchaseData){
    this.purchaseData = event
    this.purchaseDetail = this.purchaseData.details
    this.purchaseDetail.forEach(item => item.effectiveDate = item.effectiveDate?.split("T")?.[0])
    console.log(this.purchaseData)
        this.mostrarDetalles = true;
        this.nombre = this.purchaseData.user.name;
        this.rfc = this.purchaseData.user.rfc;
        this.fecha = this.purchaseData.date;
        this.PurchaseId=this.purchaseData.id;
        this.facturadoPDF=this.purchaseData.facturadoPDF;
        this.facturadoXML=this.purchaseData.facturadoXML;

        this.iva=this.purchaseData.iva
        this.total=this.purchaseData.total
        this.descuento=this.purchaseData.discount
        this.subtotal = (this.purchaseData.total-this.purchaseData.iva)+this.purchaseData.discount;
        this.monthly=this.purchaseData.toMonths
        this.months=this.purchaseData.months
        this.comission=this.purchaseData.commission
        this.bank=this.purchaseData.bank
      this.mostrarDetalles = true;
  }
  trackDetails(index:number, item:IPurchaseDetail){
    return item.productId
  }

  trackPurchase(index:number, item:IPurchaseData){
    return item.id;
  }

  getLicenciamiento(type:boolean){
    return type? "Mono-empresa":"Multi-empresa"
  }

  checkCombo(list:IPurchaseDetail[]){
    var thereIsCombo=false;
    list.forEach(product =>{
      if(product.isInCombo){
        thereIsCombo=true;
      }
    })
    return thereIsCombo;
  }

  checkProducts(list:IPurchaseDetail[]){
    var thereIsNoCombo=false;
    list.forEach(product =>{
      if(!product.isInCombo){
        thereIsNoCombo=true;
      }
    })
    return thereIsNoCombo;
  }

  getContenidoBoton(status:Boolean):string{
    if(status)
      return "Sí"
    else
      return "No"
  }


  abrir(nombre:String, rfc:String, fecha:String, details:IPurchaseDetail[], purchaseID:string, purchaseData:IPurchaseData, facturaPDF:boolean, facturaXML:boolean){
    this.mostrarDetalles = true;
    this.nombre = nombre;
    this.rfc = rfc;
    this.fecha = fecha;
    this.purchaseDetail=details;
    this.purchaseData=purchaseData;
    this.PurchaseId=purchaseID;
    this.facturadoPDF=facturaPDF;
    this.facturadoXML=facturaXML;

    this.iva=this.purchaseData.iva
    this.total=this.purchaseData.total
    this.descuento=this.purchaseData.discount
    this.subtotal = (purchaseData.total-purchaseData.iva)+purchaseData.discount;
    this.monthly=purchaseData.toMonths
    this.months=purchaseData.months
    this.comission=this.purchaseData.commission
    this.bank=this.purchaseData.bank
  }

  cerrar(){
    this.mostrarDetalles= false;
    this.recargar();
  }

  mostrarDesglose(){
    this.desloseVisible=!this.desloseVisible;
    if(this.desloseVisible){
      this.desgloseMensaje="Ocultar Desglose"
    }else{
      this.desgloseMensaje="Ver Desglose"
    }
  }
  mostrarDetallesProducto(){
    this.detallesProductoVisible=!this.detallesProductoVisible;
    if(this.detallesProductoVisible){
      this.detallesProductoMensaje="Ocultar detalles de producto"
    }else{
      this.detallesProductoMensaje="Mostrar detalles de producto"
    }
  }
  mostrarDeslgoseCompra(){
    this.desgloseCompraVisible=!this.desgloseCompraVisible;
    if(this.desgloseCompraVisible){
      this.desgloseCompra="Ocultar desglose de compra"
    }else{
      this.desgloseCompra="Mostrar desglose de compra"
    }
  }

  async redirigirFacturaXML(cargado:boolean){
    if(cargado){
      await this.getURLFile(this.PurchaseId, 'xml', 'xml')
    }
  }

  async redirigirFacturaPDF(cargado:boolean){
    if(cargado){
      await this.getURLFile(this.PurchaseId, 'facturas')
    }
  }

  async redirigirCertificado(cargado:boolean, Id:string){
    if(cargado){
      await this.getURLFile(Id, 'certificados')
    }
  }

  async getURLFile(name:string, subdirectory:string,ext="pdf"){
    const file: IFileRequest= {
      objectKey: name+"."+ext,
      subDirectory: subdirectory,
      duration: 30
    };
    var url=""
    if(subdirectory=="xml"){
      await this.sithecCommonApiService
      .downloadURLFile(file).subscribe((res) => {
        if(res){
          this.downloadFile(res)
        }
      });
    }else{
      await this.sithecCommonApiService
      .getURLFile(file).subscribe((res) => {
        url=res
        window.open(url, '_blank');
      });
    }
  }

  downloadFile(file: any) {
    let fileContents: string = file.fileContents
    let fileDownloadName: string = file.fileDownloadName
    let contentType: string = file.contentType
    // Decodificar el contenido base64
    const decodedContent = atob(fileContents);
 
    // Convertir el contenido a un array de bytes
    const byteCharacters = new Array(decodedContent.length);
    for (let i = 0; i < decodedContent.length; i++) {
      byteCharacters[i] = decodedContent.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteCharacters);
 
    // Crear un Blob a partir del contenido
    const blob = new Blob([byteArray], { type: contentType });
 
    // Crear una URL para el Blob
    const url = window.URL.createObjectURL(blob);
 
    // Crear un enlace <a> en el DOM y simular un clic en él para iniciar la descarga
    const a = document.createElement('a');
    a.href = url;
    a.download = fileDownloadName;
    document.body.appendChild(a);
    a.click();
 
    // Liberar el objeto URL creado
    window.URL.revokeObjectURL(url);
  }

  async setFile(event: any, tipoArchivo:String, Id:string) {
    if (!event || !event.target || !event.target.files || event?.target?.files?.length == 0)
        return;

    this.file = event.target.files[0];
    this.mostrarWarning=false
    console.log("El archivo es: "+tipoArchivo)
    this.tipoArchivo=tipoArchivo

    if(tipoArchivo=='PDF' ||tipoArchivo=='XML' ){
      this.fileName=this.PurchaseId
    }
    else{
      this.fileName=Id
    }  
    console.log("el nombre es: "+this.fileName)

    if(tipoArchivo=='PDF' || tipoArchivo=='Certificado'){
      if(!this.file.type.includes('pdf'))
      {
        Swal.fire(
          '¡Error!',
          'El archivo no es un PDF. Por favor, suba otro con el formato correcto.',
          'error'
        )
        this.file = null;
      }else{
        this.format="application/pdf"
        if(tipoArchivo=='PDF'){
          if(event){
            this.formData = new FormData();      
            this.formData.append('fileUpload', this.file, "factura.pdf");
            this.formData.append('subDirectory', "facturas");
            this.formData.append('reference', this.PurchaseId);
            this.loadingPDF = true;
            this.saveObject()
          }
        }else{
          if(event){
            this.formData = new FormData();      
            this.formData.append('fileUpload', this.file, "certificado.pdf");
            this.formData.append('subDirectory', "certificados");
            this.formData.append('reference', this.fileName);
            this.loadingCertificate = true;
            this.saveObject()
          }
        }
      }
    }else if(tipoArchivo=='XML'){
      if(!this.file.type.includes('xml'))
      {
        Swal.fire(
          '¡Error!',
          'El archivo no es un XML. Por favor, suba otro con el formato correcto.',
          'error'
        )
        this.file = null;
      }else{
        this.format="text/xml"
        //this.fileNameXML=this.file.name
        this.mostrarWarning=false
        
        if(event){
          this.formData = new FormData();      
          this.formData.append('fileUpload', this.file, "factura.xml");
          this.formData.append('subDirectory', "xml");
          this.formData.append('reference', this.PurchaseId);
          this.loadingXML = true;
          this.saveObject()
        }
      }
    }
    
  }
  debitOrCredit(type: any){
    if(type == 'debit')
      return "Tarjeta de débito"
    if(type == 'credit')
      return "Tarjeta de crédito"

    return ""
  }
  async sendFile(){
    if(this.file==null){
      this.mostrarWarning=true
      this.message="No se ha cargado ningún archivo"
    }else{
      
      if(this.tipoArchivo=="Certificado"){
        const file: IFileInformation = {
          purchaseDetailId: this.fileName,
          purchaseID: this.PurchaseId
        };
        await this.saveCertificado(file)
      }else if(this.tipoArchivo=="PDF"){
        const file: IFileInformation = {
          purchaseID: this.PurchaseId
        };
        await this.saveFacturaPDF(file)
      }else if(this.tipoArchivo=="XML"){
        const file: IFileInformation = {
          purchaseID: this.PurchaseId
        };
        await this.saveFacturaXML(file)
      }

      /* console.log(this.url) */
      // let res = await this.apiService.saveFileInS3(this.url, this.file);
      /* console.log(res);   */

      if(this.tipoArchivo=="Certificado"){
        this.purchaseDetail.forEach(purchase => {
          if(purchase.id==this.fileName){
            purchase.certificado=true;
          }
        });
      }else if(this.tipoArchivo=="PDF"){
        this.facturadoPDF=true;
      }else if(this.tipoArchivo=="XML"){
        this.facturadoXML=true;
      }

      Swal.fire(
        '¡Operación Exitosa!',
        'El archivo ha sido cargado correctamente',
        'success'
      )

      //setTimeout(this.recargar, 2000);
    }
  }

  async deleteFile(name:string, subdirectory:string, fileFormat:string){
    const file: IDeleteInformation = {
      objectKey: name+fileFormat,
      subDirectory: subdirectory
    };
    var eliminar=false

    await Swal.fire({
      title: '¿Estás seguro de eliminar este archivo?',
      text: "Si confirmas esta acción el archivo será eliminado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El archivo ha sido eliminado correctamente',
          'success'
        )
          eliminar=true
      } else{
        Swal.fire(
          'Operación cancelada',
          'Los cambios no se verán reflejados',
          'success'
        )
      }
    })

    if(eliminar){
      await this.apiService
      .deleteFile(file)
      .toPromise();
    
      if(subdirectory=="certificados"){
        //this.purchaseDetail[0].certificado=false;
        this.purchaseDetail.forEach(purchase => {
          if(purchase.id==name){
            purchase.certificado=false;
          }
        });
      }else if(subdirectory=="facturas"){
        this.facturadoPDF=false;
      }else if(subdirectory=="xml"){
        this.facturadoXML=false;
      }
      //setTimeout(this.recargar, 2000);
      
    }
    
  }
  recargar(){
    window.location.reload()
  }

  private async saveFacturaPDF(fileInformation: IFileInformation) {
    this.url = await this.apiService
      .postSaveFacturaPDF(fileInformation)
      .toPromise();
  } 

  private async saveFacturaXML(fileInformation: IFileInformation) {
    this.url = await this.apiService
      .postSaveFacturaXML(fileInformation)
      .toPromise();
  } 

  private async saveCertificado(fileInformation: IFileInformation) {
    this.url = await this.apiService
      .postSaveCertificado(fileInformation)
      .toPromise();
  } 

  abrirSelector(){
    this.mostrarSelector=!this.mostrarSelector
  }

  seleccionarFiltro(filtro:"pdf" | "xml" | "certificado" | "todo"){

    if(filtro=="todo"){
      this.banderaFiltros[filtro]=true
      this.banderaFiltros["pdf"]=false
      this.banderaFiltros["xml"]=false
      this.banderaFiltros["certificado"]=false
    }else{
      this.banderaFiltros["todo"]=false
      this.banderaFiltros[filtro]=!this.banderaFiltros[filtro]
    }

    this.formularioFiltros.get('todo')!.setValue(this.banderaFiltros["todo"]);
    this.formularioFiltros.get('todo')!.updateValueAndValidity();
    this.formularioFiltros.get('certificado')!.setValue(this.banderaFiltros["certificado"]);
    this.formularioFiltros.get('certificado')!.updateValueAndValidity();
    this.formularioFiltros.get('pdf')!.setValue(this.banderaFiltros["pdf"]);
    this.formularioFiltros.get('pdf')!.updateValueAndValidity();
    this.formularioFiltros.get('xml')!.setValue(this.banderaFiltros["xml"]);
    this.formularioFiltros.get('xml')!.updateValueAndValidity();
  }

  formData: any;

  saveObject(){
    if(this.formData){
      this.sithecCommonApiService
        .uploadObject(this.formData)
        .subscribe(
          (response: any) => {
            if(response){
              this.sendFile()
              // this.toastrService.showSuccess("Producto guardado")
              this.formData = null;
              // Swal.fire(
              //   'Archivo cargado',
              //   'Proceso exitoso',
              //   'success'
              // )
            }
            this.loadingXML = false;
            this.loadingPDF = false;
            this.loadingCertificate = false;
            // console.log("response",response)
          },
          ({ message }: IErrorDefinition) => {
            // this.toastr.error(message,'Error');
            // this.spinnerActive = false;
            // this.toastrService.showError(message)
          }
        ); 
    }
  }
  
  filterPurchases(){
    this.apiService
      .getPurchasesFiltered(this.filtros)
      .subscribe(
        (purchases: any) => {
            this.purchasesData=purchases.results
        },
        ({ message }: IErrorDefinition) => this.toastrService.showError(message)
      );
  }

  setDateCertificateFromInput(date: any, detailId: string){
    const data = {
      effectiveDate: date
    }
    this.apiService
      .purchaseDetailsED(data,detailId)
      .subscribe(
        (purchases: any) => {
          Swal.fire(
            '¡Operación Exitosa!',
            date? 'Vigencia actualizada': 'Vigencia eliminada',
            'success',
          )
        },
        ({ message }: IErrorDefinition) => {
          // Swal.fire(
          //   '¡Error!',
          //   message,
          //   'error'
          // )
        }
      );
  }
  setDateCertificate($event: any = null, date: any, detailId: string){
    if(!$event?.value){
      date = ""
    }
    const data = {
      effectiveDate: date
    }
    this.apiService
      .purchaseDetailsED(data,detailId)
      .subscribe(
        (purchases: any) => {
          Swal.fire(
            '¡Operación Exitosa!',
            date? 'Vigencia actualizada': 'Vigencia eliminada',
            'success',
          )
        },
        ({ message }: IErrorDefinition) => {
          // Swal.fire(
          //   '¡Error!',
          //   message,
          //   'error'
          // )
        }
      );
  }

  activeEfectiveDate(effectiveDate: any){
    if(!effectiveDate){
      this.toastr.warning("No ha seleccionado una fecha",'Alerta');
    }
  }
  editarIMEI(){
    this.editable = !this.editable;
  }
  updateIMEI(id:string){
    let content = <HTMLInputElement> document.getElementById("IMEI");
    let imei:string = content.value
    let body = {
      metadata:imei
    }
    if(imei.length >= 52 && imei.length <= 54){
      this.apiService.updateIMEI(id,body).subscribe(
        (Response) =>{
          Swal.fire(
            '¡Operación Exitosa!',
              'IMEI actualizado exitosamente',
            'success',
          )
          this.editable = !this.editable
        },
        (error) =>{
          Swal.fire(
            'Algo salió mal',
              error.message,
            'error',
          )
        }
  
      )
    }
    else{
      Swal.fire(
        'Algo salió mal',
        'La cantidad de caracteres del IMEI no puede ser mayor a 54 y menor a 52',
        'error',
      )
    }
  }
}