import { Component, OnInit } from '@angular/core';
import { IUser } from '@common/lib/models/purchase.model';
import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { SpinnerService } from "@common/lib/services/spinner.service";
import { ToastrMessageService } from "@common/lib/services/message.service";
import { finalize } from "rxjs/operators";
import { IErrorDefinition } from "@common/lib/models/error-definition.model";
import { IFileRequest } from '@common/lib/models/file-request';
import { SithecCommonApiService } from '@common/lib/services/sithec-common-api.service';
import Swal from 'sweetalert2';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { FormGroup, Validators, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IUserAdmin } from '@common/lib/models/user.model';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.less']
})
export class CreateUserComponent implements OnInit {
  data: any;
  spinnerActive = false;
  idUser:string = "";
  myForm!: FormGroup;
  editUser!: IUserAdmin|undefined;
  generatedPassword:string = "";
  enablePasswordButton:boolean = false;
  showSuccessPassword:boolean = false;
  copyText:string = "";
  hidePasswordModule:boolean = true;
  constructor(
    private apiService:AdminApiServiceService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService, 
    private sithecCommonApiService:SithecCommonApiService,
    private searchService:GenericSearchService,
    private formBuilder:FormBuilder,
    private routeActive:ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService
  ) { } 

  ngOnInit(): void {
   // this.spinnerService.show()
   this.copyText = "Copiar";
  this.idUser = this.routeActive.snapshot.params['idUsuario'];
  console.log(this.idUser)
  if(this.idUser){
    var user = sessionStorage.getItem("editUser")
    if(user)
    this.editUser = JSON.parse(user)
    console.log(this.editUser)
  }
    this.myForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      checkbox1: [true],
      checkbox2: [true],
      checkbox3: [true],
      contrasena: ['']
    }, { validator: this.passwordValidatorIfCheckbox1Unchecked.bind(this) });
  
  if(this.idUser){
    var user = sessionStorage.getItem("editUser")
    if(user)
    this.editUser = JSON.parse(user)
    console.log(this.editUser)
    this.setUser()
  }
  }
  passwordValidatorIfCheckbox1Unchecked(control: AbstractControl): ValidationErrors | null {
    const checkbox1 = control.get('checkbox1')?.value;

    if (!checkbox1) {
      const contrasenaControl = control.get('contrasena');
      const contrasena = contrasenaControl?.value;

      if (!contrasena) {
        return { requiredPassword: true }; // Otra validación si se requiere
      }

      if (!/^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(contrasena)) {
        return { invalidPasswordFormat: true }; // Otra validación si se requiere
      }

      if (contrasena.length < 8 || contrasena.length > 26) {
        return { invalidPasswordLength: true }; // Otra validación si se requiere
      }
    }

    return null;
  }

  submitForm(): void {
    // Obtener los valores del formulario
    const formValues = this.myForm.value;
    var sendInfo
    // Crear el objeto JSON
    if(!this.editUser){
      sendInfo = {
        userName: formValues.correo,
        name: formValues.nombre,
        lastName: formValues.apellido,
        password: formValues.checkbox1 ? "" : formValues.contrasena,
        generatePassword: formValues.checkbox1,
        sendEmail: formValues.checkbox2,
        NeedsPasswordReset: formValues.checkbox3
      };
    }
    
    else{
      var bool = false;
      sendInfo = {
        userName: formValues.correo,
        name: formValues.nombre,
        lastName: formValues.apellido,
        password: "",
        generatePassword: bool,
        sendEmail: bool,
        NeedsPasswordReset: bool
      };
    }
    // Aquí puedes hacer lo que quieras con el objeto JSON, como enviarlo a una API
    console.log(sendInfo);
    if(!this.editUser){
      this.apiService.createUserAdmin(sendInfo).subscribe(
      (response: any) => {
        if(response.id){
          console.log("response",response)
          this.generatedPassword = response.password;
          this.toastr.success('Usuario creado','Acción exitosa');
          if(formValues.checkbox3){
            this.navigate();
          }
        }
       
      },
      ({ message }: IErrorDefinition) => {
        this.toastr.error(message,'Error');
        this.spinnerActive = false;
        // this.toastrService.showError(message)
      }
    );
    }
    else{
      this.apiService.updateUserAdmin(this.editUser.id,sendInfo).subscribe(
        (response: any) => {
          if(response){
            // this.toastrService.showSuccess("Producto guardado")
            
            this.toastr.success('Usuario Editado','Acción exitosa');
            this.router.navigate(['/main-page/usuarios']);
          }
          console.log("response",response)
        },
        ({ message }: IErrorDefinition) => {
          this.toastr.error(message,'Error');
          // this.toastrService.showError(message)
        }
      );
    }
  }
 
  

  getUser(id:string){
  this.apiService.searchUserAdmin(id).subscribe(
    (response: any) => {
      if(response.id){
        
      }
      console.log("response",response)
    },
    ({ message }: IErrorDefinition) => {
      this.toastr.error(message,'Error');
      this.spinnerActive = false;
      // this.toastrService.showError(message)
    }
  );
  }
    get getSaveName(){
      return this.idUser? "Editar":"Guardar";
    }
    get getTitle(){
      return this.idUser? "Usuarios > Editar":"Usuario > Nuevo Usuario";
    }
  setUser(){
    this.myForm.patchValue({
      nombre: this.editUser?.name,
      apellido: this.editUser?.lastName,
      correo: this.editUser?.username,
      checkbox1: true,
      checkbox2: this.editUser?.needsPasswordReset,
      checkbox3: this.editUser?.sendEmail,
    });
    this.hidePasswordModule = false;
  }
  copiarPortapapeles(){
    const tempTextArea: HTMLTextAreaElement = document.createElement("textarea");
    tempTextArea.value = this.generatedPassword;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    this.enablePasswordButton = true;
    this.copyText = "Copiado!";
  }
  verifyPassword(){
    const formValues = this.myForm.value;
    if(!this.editUser){
      if(formValues.checkbox3){
        this.showSuccessPassword=false;
        
      }
      else {
        
        this.showSuccessPassword=true;
      }
    }
    else{
      this.showSuccessPassword=false;
    }
    
  }
  navigate(){
    this.router.navigate(['/main-page/usuarios']);
  }
}
