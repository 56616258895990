import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { Component, OnInit } from '@angular/core';
import { IErrorDefinition } from '@common/lib/models/error-definition.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { SpinnerService } from '@common/lib/services/spinner.service';
import { finalize } from 'rxjs/operators';
import { forEach } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-products',
  templateUrl: './create-products.component.html',
  styleUrls: ['./create-products.component.less']
})
export class CreateProductsComponent implements OnInit {
  
  createMode = true;
  spinnerActive = false;
  imageURL = "";
  fileImage?: File;
  fields = [
    {
      label: 'Tipo de Producto',
      mandatory: true,
      fieldName: 'productType',
      type: 'select',
      options: [
        {
          text: 'ANTIVIRUS',
          value: 'ANTIVIRUS'
        },
        {
          text: 'CONTPAQi',
          value: 'CONTPAQi'
        },
        {
          text: 'EVALUATEC',
          value: 'EVALUATEC'
        },
        {
          text: 'MICROSOFT',
          value: 'MICROSOFT'
        },
        {
          text: 'SERVICIOS',
          value: 'SERVICIOS'
        },
        {
          text: 'TS_PLUS',
          value: 'TS_PLUS'
        },
        {
          text: 'XML_SAT',
          value: 'XML_SAT'
        }
      ],
      value: "",
      hide: false
    },
    {
      label: 'Código de Producto',
      mandatory: true,
      placeholder: 'Ej. 157842',
      fieldName: 'reference',
      type: 'text',
      value: "",
      hide: false
    },
    {
      label: 'Nombre del Producto',
      mandatory: true,
      placeholder: 'Ej. Microsoft 365 Business Standard Anual',
      fieldName: 'name',
      type: 'text',
      value: "",
      hide: false
    },
    {
      label: 'Categoría Primaria',
      mandatory: true,
      fieldName: 'productCategoryEntityId',
      type: 'select',
      options: [],
      showOnlyWhithOptions: false,
      value: "",
      hide: false
    },
    {
      label: 'Categoría Secundaria',
      mandatory: false,
      fieldName: 'productCategoryEntityId2',
      type: 'select',
      options: [],
      showOnlyWhithOptions: true,
      value: "",
      hide: false
    },
    {
      label: 'Periodicidad',
      mandatory: true,
      fieldName: 'lapse',
      type: 'select',
      options: [
        {
          text: 'Diaria',
          value: 'Day'
        },
        {
          text: 'Mensual',
          value: 'Month'
        },
        {
          text: 'Anual',
          value: 'Year'
        },
        {
          text: 'Permanente',
          value: 'Permanent'
        }
      ],
      value: "",
      hide: false
    },
    {
      label: 'Descripción/Características',
      mandatory: true,
      placeholder: 'Ej. Detalla la descripción o las características',
      fieldName: 'description',
      type: 'textarea',
      tagClass: '',
      value: "",
      hide: false
    },
    {
      label: 'Aplica en combo',
      mandatory: false,
      fieldName: 'comboEnable',
      type: 'select',
      options: [
        {
          text: 'No',
          value: false
        },
        {
          text: 'Sí',
          value: true
        }
      ],
      value: "",
      hide: true
    },
    {
      label: 'Precio (antes de impuestos)',
      mandatory: true,
      placeholder: '$ 000,000.00 MXN',
      fieldName: 'price',
      type: 'money',
      value: "",
      hide: false
    },
    {
      label: 'Costo 1RFC',
      mandatory: false,
      placeholder: '$ 000,000.00 MXN',
      fieldName: 'costMonoRFC',
      type: 'money',
      value: "",
      hide: true
    },
    {
      label: 'Costo Usuario Adicional 1RFC',
      mandatory: false,
      placeholder: '$ 000,000.00 MXN',
      fieldName: 'costMonoRFCUA',
      type: 'money',
      value: "",
      hide: true
    },
    {
      label: 'Costo MultiRFC',
      mandatory: true,
      placeholder: '$ 000,000.00 MXN',
      fieldName: 'costMultiRFC',
      type: 'money',
      value: "",
      hide: true
    },
    {
      label: 'Costo Usuario Adicional MultiRFC',
      mandatory: false,
      placeholder: '$ 000,000.00 MXN',
      fieldName: 'costMultiRFCUA',
      type: 'money',
      value: "",
      hide: true
    },
    {
      label: 'Descuento',
      mandatory: false,
      placeholder: '% Descuento',
      fieldName: 'discount',
      type: 'percent',
      text: 'Aplica descuento',
      value: 0,
      hide: false
    },
    {
      label: '',
      mandatory: false,
      fieldName: 'headerImage',
      fieldNameDependency: 'referende',
      type: 'file',
      title: 'Cargar',
      text: 'Logotipo de producto',
      description: 'PNG (400 px X 400 px)',
      value: "",
      hide: false
    }
  ]

  data: any;
  
  formBodyHistoric: any;
  productCategoryArray: any = [];
  productCategoriesCat1: any = [];
  productCategoriesCat2: any = [];
  idProduct = "";
  constructor(
    private router: Router,
    private routeActive: ActivatedRoute,
    private apiService:AdminApiServiceService,
    private spinnerService:SpinnerService,
    private toastrService:ToastrMessageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getProductCategories();
    this.idProduct = this.routeActive.snapshot.params['id'];
    if(this.idProduct){
      this.getProductById();
      this.createMode=false;
    }
  }

  onSubmit(formBody: any){
    let productDependencies = null;
    if(formBody.reference){
      formBody.reference = formBody.reference.trim()
    }
    switch(formBody.productType){
      case "EVALUATEC":
        productDependencies = {
          "dependency": "Email"
        }
        if(this.productDependencies){
          productDependencies = this.productDependencies
        }
      break;
      case "XML_SAT":
        productDependencies = {
          "dependency": "IMEI"
        }
        if(this.productDependencies){
          productDependencies = this.productDependencies
        }
      break;
      default:
      break;
    }
    formBody.headerImage = "png";
    if(!formBody?.discount){
      formBody.discount = 0;
    }
    if(formBody?.productCategoryEntityId2){
      formBody.productCategoryEntityId = formBody.productCategoryEntityId2;
    }

    let sendData = {
      ...formBody,
      "hasMultiUsers": false,
      "dependency": productDependencies? productDependencies.dependency: null
    }

    this.spinnerActive = true;

    if(this.createMode){
      // if(this.formData)
      //   this.formData.append('reference', sendData.reference.trim());
      // this.saveImage();
      
      this.toFormData(sendData)
      this.apiService
        .createProduct(this.formData)
        .subscribe(
          (response: any) => {
            if(response.id){
              // this.toastrService.showSuccess("Producto guardado")
              this.toastr.success('Producto guardado','Acción exitosa');
              this.router.navigate(['/main-page/productos']);
            }
            console.log("response",response)
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.error(message,'Error');
            this.spinnerActive = false;
            // this.toastrService.showError(message)
          }
        );
    }else{
      // if(this.formData)
      //   this.formData.append('reference', sendData.reference.trim());
      // this.saveImage();
      this.toFormData(sendData)
      if(this.fileImage){
        sendData.isChangeImage=true;
      }
      this.apiService
        .updateProduct(this.formData,this.idProduct)
        .subscribe(
          (response: any) => {
            if(response.id){
              // this.toastrService.showSuccess("Producto actualizado")
              this.toastr.success('Producto actualizado','Acción exitosa');
              this.router.navigate(['/main-page/productos']);
            }
            // console.log("response",response)
          },
          ({ message }: IErrorDefinition) => {
            // this.toastrService.showError(message)
            this.spinnerActive = false;
            this.toastr.error(message,'Error');
          }
        );
    }
  }

  toFormData(sendData: any){
    this.formData = new FormData();
    if(sendData){
      if(this.fileImage)
        this.formData.append(this.capitalizarPrimeraLetra('fileUpload'), this.fileImage, "imagen.png");
      for (const key in sendData) {
        if(sendData[key] == null || sendData[key]=='')
          switch(key){
            case "price":
            case "costMonoRFC":
            case "costMonoRFCUA":
            case "costMultiRFC":
            case "costMultiRFCUA":
            case "discount":
              sendData[key] = 0;
              break;
            case "comboEnable": 
              sendData[key] = false;
              break;
          }
        if(key != 'headerImage')
          if (Object.prototype.hasOwnProperty.call(sendData, key)) {
            this.formData.append(this.capitalizarPrimeraLetra(key), sendData[key]);
          }
      }
    }
  }

  capitalizarPrimeraLetra(palabra: string): string {
    if (palabra.length === 0) {
        return palabra;
    }
    return palabra.charAt(0).toUpperCase() + palabra.slice(1);
  }

  onChange(formBody: any){
    if(this.productCategoryArray && formBody.productCategoryEntityId != this.formBodyHistoric?.productCategoryEntityId){
      let findField = this.fields.find((item: any) => item.fieldName === "productCategoryEntityId2")
      if(findField){
        this.productCategoriesCat2 = this.productCategoryArray.filter((item: any) => item.parentCategoryId == formBody.productCategoryEntityId);
        findField.options = this.getProductCategoriesCat2;
        if(findField.options.length){
          // console.log("Entra")
          findField.hide = false;
        }else{
          // console.log("Entra2")
          if(!formBody.productCategoryEntityId){
            findField.hide = true;
            // console.log("Entra3")
          }
        }
      }
    }
    if(formBody!=this.formBodyHistoric){
      let band = false;
      if(formBody.productType == "CONTPAQi")
        band = true;
      let findField = this.fields.find((item: any) => item.fieldName === "price")
      if(findField){
        findField.hide = band;
      }
      findField = this.fields.find((item: any) => item.fieldName === "costMonoRFC")
      if(findField){
        findField.hide = !band;
      }
      findField = this.fields.find((item: any) => item.fieldName === "costMonoRFCUA")
      if(findField){
        findField.hide = !band;
      }
      findField = this.fields.find((item: any) => item.fieldName === "costMultiRFC")
      if(findField){
        findField.hide = !band;
      }
      findField = this.fields.find((item: any) => item.fieldName === "costMultiRFCUA")
      if(findField){
        findField.hide = !band;
      }
      findField = this.fields.find((item: any) => item.fieldName === "comboEnable")
      if(findField){
        findField.hide = !band;
      }
      if(this.productCategoryArray && formBody.productType != this.formBodyHistoric?.productType)
        if(band){
          let findField = this.fields.find((item: any) => item.fieldName === "productCategoryEntityId")
          if(findField){
            if(findField.options != this.getProductCategoriesCat1){
              this.productCategoriesCat1 = this.productCategoryArray.filter((item: any) => item.parentCategoryId == null && item.name=='CONTPAQi');
              findField.options = this.getProductCategoriesCat1;
            }
          }
        }else{        
          let findField = this.fields.find((item: any) => item.fieldName === "productCategoryEntityId")
          if(findField){
            if(findField.options != this.getProductCategoriesCat1){
              this.productCategoriesCat1 = this.productCategoryArray.filter((item: any) => item.parentCategoryId == null && item.name!='CONTPAQi');
              findField.options = this.getProductCategoriesCat1;
            }
          }
        }
    }
    this.formBodyHistoric = formBody;
  }

  getProductCategories(){
    this.apiService
      .getProductCategories()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (productCategories: any) => {
            // console.log("productCategories",productCategories)
            if(productCategories){
              this.productCategoryArray = productCategories.results as any[];
              this.productCategoriesCat1 = this.productCategoryArray.filter((item: any) => item.parentCategoryId == null);
              let findField = this.fields.find((item: any) => item.fieldName === "productCategoryEntityId")
              if(findField){
                findField.options = this.getProductCategoriesCat1;
              }
              if(!this.createMode){
                this.productCategoriesCat2 = this.productCategoryArray.filter((item: any) => item.parentCategoryId == this.formBodyHistoric?.productCategoryEntityId);
                let findField = this.fields.find((item: any) => item.fieldName === "productCategoryEntityId2")
                if(findField){
                  findField.options = this.getProductCategoriesCat2;
              }
              }
            }
            // this.productsData=products
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
  }

  productDependencies: any;
  getProductById(){
    this.apiService
      .getProduct(this.idProduct)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (product: any) => {
            if(product){
              this.data = {
                ...product,
                productCategoryEntityId2: product.productCategory.parentCategoryId? product.productCategory.id: "",
                productCategoryEntityId: product.productCategory.parentCategoryId? product.productCategory.parentCategoryId: product.productCategory.id
              };
              if(this.data.headerImage){
                let name = this.data.headerImage?.split("/").reverse()[0];
                this.getImage(name)
              }
              if(product.productCategory.parentCategoryId){
                this.data.productCategoryEntityId = product.productCategory.parentCategoryId;
              }
              if(this.data.productDependencies){
                this.productDependencies = this.data.productDependencies;
              }
            }
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
  }

  get getProductCategoriesCat1(){
    let productCategoriesCat1: any[] = [];
    this.productCategoriesCat1?.forEach((item: any) =>{
      productCategoriesCat1.push({
        text: item.name,
        value: item.id
      })
    })
    this.productCategoriesCat1 = productCategoriesCat1;
    return productCategoriesCat1;
  }

  get getProductCategoriesCat2(){
    let productCategoriesCat2: any[] = [];
    this.productCategoriesCat2?.forEach((item: any) =>{
      productCategoriesCat2.push({
        text: item.name,
        value: item.id
      })
    })
    this.productCategoriesCat2 = productCategoriesCat2;
    return productCategoriesCat2;
  }

  get getTitle(){
    return this.idProduct? "Productos > Editar":"Productos > Nuevo Producto";
  }

  get getSaveName(){
    return this.idProduct? "Editar":"Guardar";
  }

  formData: any;

  uploadImage($event: any){
    // console.log("$event",$event)
    if($event){
      this.fileImage = $event;
    }
  }

  saveImage(){
    if(this.formData)
      this.apiService
        .uploadImage(this.formData)
        .subscribe(
          (response: any) => {
            if(response){
              // this.toastrService.showSuccess("Producto guardado")
              this.toastr.success('Imagen cargada','Acción exitosa');
            }
            // console.log("response",response)
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.error(message,'Error');
            this.spinnerActive = false;
            // this.toastrService.showError(message)
          }
        );  
    }

    getImage(name: string){
      const productInfo = {
        "objectKey": name,
        "subDirectory": "products",
        "duration": 30
      };
      this.apiService
        .getImage(productInfo)
        .subscribe(
          (response: any) => {
            if(response){
              //Verificar que existe imagen
              this.imageURL = response;
              // console.log(this.imageURL,"this.imageURL")
            } 
          },
          ({ message }: IErrorDefinition) => {
            // this.toastr.error(message,'Error');
            this.toastrService.showError(message)
          }
        );
    }
}
