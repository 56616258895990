import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/admin-dashboard/dashboard/dashboard.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { AdminAuthenticationGuardService } from './services/admin-authentication-guard.service';
import { CargarArchivosComponent } from './components/cargar-archivos/cargar-archivos.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CreateProductsComponent } from './components/create-products/create-products.component';
import { CreateBannerComponent } from './components/create-banner/create-banner.component';
import { BannersComponent } from './components/banners/banners.component';
import { CreateUserComponent } from './components/create-user/create-user.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'account/authentication',
    pathMatch: 'full'
  },
  {
    path: 'error',
    loadChildren: () => import("@common/lib/components/error-page/error-page.module").then(module => module.ErrorPageModule)
  },
  {
    /* path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () => import("./components/admin-dashboard/admin-dashboard.module").then(module => module.AdminDashboardModule), */
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AdminAuthenticationGuardService]
  },
  {
    path: 'account',
    loadChildren: () => import("@common/lib/components/authentication/authentication.module").then(module => module.AuthenticationModule),
  },
  
  {
    path: 'landing',
    pathMatch: "full",
    redirectTo:'main-page/ventas'
  },
  {
    path: 'load-file',
    component: CargarArchivosComponent
  },
  {
    path: 'main-page',
    component: MainPageComponent,
    canActivate: [AdminAuthenticationGuardService],
    children: [
      {
        path: 'usuarios',
        component: UsuariosComponent
      },
      {
        path: 'clientes',
        component: ClientesComponent
      },
      {
        path: 'ventas',
        component: VentasComponent
      },
      {
        path: 'productos',
        component: ProductosComponent
      },
      {
        path: 'productos/crear',
        component: CreateProductsComponent
      },
      {
        path: 'productos/editar/:id',
        component: CreateProductsComponent
      },
      {
        path: 'banners',
        component: BannersComponent
      },
      {
        path: 'banners/crear',
        component: CreateBannerComponent
      },
      {
        path: 'banners/editar/:id',
        component: CreateBannerComponent
      },
      {
        path: 'usuarios/crear',
        component: CreateUserComponent
      },
      {
        path: 'usuarios/editar/:idUsuario',
        component: CreateUserComponent
      },
      {
        path: '',
        pathMatch: "full",
        redirectTo:'main-page/ventas'
      }
    ]
  },
  {
    path: '**',
    loadChildren: () => import("@common/lib/components/error-page/error-page.module").then(module => module.ErrorPageModule)
  }
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
