import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IErrorDefinition } from '@common/lib/models/error-definition.model';
import { ICardInformationConfiguration } from '@common/lib/models/product.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { SpinnerService } from '@common/lib/services/spinner.service';
import { size } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.less']
})
export class ProductosComponent implements OnInit {

  //Paginator
  currentIndex: number = 1;
  totalItems: number = 10;
  sizePage: number = 10;
  search = new FormControl('');
  formSubscription!:Subscription

  productsData:ICardInformationConfiguration[]=[];
  productData!:ICardInformationConfiguration;
  showConfirmDelete = false;

  columns = [
    {
      title: 'Cod Prod', 
      fieldName: 'reference',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Categoría', 
      fieldName: 'productCategory.name',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Nombre del Producto',
      fieldName: 'name',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Tipo',
      fieldName: 'productType',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Precio',
      fieldName: 'price',
      type: 'price',
      tagClass: ''
    },
    {
      title: 'Acciones',
      fieldName: 'detalles',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/details.svg',
          customClass: 'icon',
          actionId: 1,
          type: 'image',
        },
        {
          text: 'assets/images/edit.svg',
          customClass: 'icon',
          actionId: 2,
          type: 'image',
        },
        {
          text: 'assets/images/delete.svg',
          customClass: 'icon',
          actionId: 3,
          type: 'image',
        }
      ]
    },
  ]

  constructor(
    private router: Router,
    private apiService:AdminApiServiceService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService,
    private searchService:GenericSearchService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show()
    this.getProducts();
  }

  ngAfterViewInit(){
    this.formSubscription=this.search.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.currentIndex = 1;
      this.getProducts()
    })
  } 
  ngOnDestroy(): void {
    if(this.formSubscription){
      this.formSubscription.unsubscribe()
    }
    
  }

  async tableButton(event:any){
    this.productData=event.object
    // console.log("event",event)
    switch(event.btn.actionId){
      case 1:
        this.router.navigate(['/main-page/productos/editar/'+this.productData.id]);
        break;
      case 2:
        this.router.navigate(['/main-page/productos/editar/'+this.productData.id]);
        break;
      case 3:
        this.showConfirmDelete = true;
        break;
    }
  }

  getProducts(){
    this.apiService
      .getProducts(this.sizePage,this.currentIndex - 1, this.search.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (products: any) => {
            if(products){
              this.totalItems=products.totalCount;
              this.productsData=products.results;
            }
              // this.productsData=products
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
  }

  onInputChange(event: any) {
    // this.searchService.setSearchValue(event.target.value);
  }

  closeModalDelete(){
    this.showConfirmDelete = false;
  }

  confirmDelete(){
    this.apiService
      .deleteProduct(this.productData.id!)
      .subscribe(
          (products: any) => {
            if(products){
              this.getProducts();
              this.showConfirmDelete = false;
              this.toastr.success('Producto eliminado','Acción exitosa');
            }
              // this.productsData=products
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.success(message,'Error');
            // this.toastrService.showError(message)
          }
        );
  }

  changeCurrentIndex(index: number){
    this.currentIndex = index;
    this.getProducts();
  }

}
