<div class="contenido">
    <div class="recuadro">
        <div class="header-title">
            <p class="view-information">{{getTitle}}</p>
        </div>
        <div class="contenedor-formulario">
            <form [formGroup]="myForm" (ngSubmit)="submitForm()">
              <div class="div-column">
                <div>
                  <label class="form-label" for="nombre">Nombre:</label>
                  <input class="form-input" type="text" formControlName="nombre">
                  <div *ngIf="myForm.get('nombre')?.errors && myForm.get('nombre')?.touched">
                    El nombre es obligatorio.
                  </div>
                </div>
              
                <div>
                  <label class="form-label" for="apellido">Apellido:</label>
                  <input class="form-input" type="text" formControlName="apellido">
                  <div *ngIf="myForm.get('apellido')?.errors && myForm.get('apellido')?.touched">
                    El apellido es obligatorio.
                  </div>
                </div>
              
                <div>
                  <label class="form-label" for="correo">Correo electrónico:</label>
                  <input class="form-input" type="email" formControlName="correo">
                  <div *ngIf="myForm.get('correo')?.errors && myForm.get('correo')?.touched">
                    El correo electrónico es obligatorio.
                  </div>
                  <div *ngIf="myForm.get('correo')?.errors && myForm.get('correo')?.touched">
                    El correo electrónico no es válido.
                  </div>
                </div>
              <div >

                <label  *ngIf="hidePasswordModule" class="form-label" for="contrasena">Contraseña:</label>
                <div class="center" *ngIf="hidePasswordModule">
                  
                  <input type="checkbox" class="generate-password-button disabled" formControlName="checkbox1" [disabled]="editUser!==null">
                  <label class="password" for="checkbox1">Crear una contraseña automática</label>
                </div>
                <div *ngIf="myForm.get('checkbox1')?.value === false && hidePasswordModule">
                    <p>Las contraseñas deben contener entre 8 y 26 caracteres, y usar una combinación de al menos 3 de los siguientes elementos: letras mayúsculas, letras minúsculas, números y símbolos.</p>
                    
                    <input class="form-input" type="password" formControlName="contrasena">
                    <div *ngIf="myForm.get('contrasena')?.errors">
                      La contraseña no es válida.
                    </div>
                  </div>
                <div class="center"  *ngIf="hidePasswordModule">
                  <input class="generate-password-button" type="checkbox" formControlName="checkbox2">
                  <label class="password" for="checkbox2">Requerir el usuario cambie su contreaseña cuando inice sesión por primera vez</label>
                </div>
                <div class="center"  *ngIf="hidePasswordModule">
                  <input  class="generate-password-button" type="checkbox" formControlName="checkbox3">
                  <label class="password" for="checkbox3">Enviar contraseña por correo electrónico al finalizar</label>
                </div>
              </div>
                
                <div class="buttons" *ngIf="!spinnerActive">
                  <button class="save-button" type="submit" [disabled]="myForm.invalid" (click)="verifyPassword()">Enviar</button>
                  <button class="cancel-button" routerLink="/main-page/usuarios">Cancelar</button>
                </div>
              </div>
            </form>
            
        </div>
    </div>
</div>
<div id="modal-cambiar-contrasena" class="modal" [ngClass]="{'show d-block':showSuccessPassword}">
  <div class="modal-contenido ">
      <div class="modal-cabecera card-header">
          <p class="modal-titulo">Contraseña generada con éxito</p>
      </div>
      <div class="modal-cuerpo">
              <div class="campo">
                  <div class="row palomita"><img src="../../../../../assets/images/check_exito.svg" alt="" style="width: 10%;"></div>
                  <div class="row"><p class="modal-subtitulo" style="text-align: center;">Te compartimos la contraseña actualizada</p></div>
                  
                  
              </div>
              <div class="campo buttons2">
                  <input class="form-input" type="text" [value]="generatedPassword">
                  <button (click)="copiarPortapapeles()" class="accept-button">{{copyText}}</button>
              </div>
      </div>        
              <div class="buttons2 confirm">
                  <button type="submit" [ngClass]="{'accept-button': enablePasswordButton, 'cancel-button': !enablePasswordButton}" (click)="navigate()" [disabled]="!enablePasswordButton">Guardar</button>
              </div>
      
  </div>
</div>