import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProductInformationComponent } from "./product-information/product-information.component";
import { ProductSpecificationsComponent } from "./product-specifications/product-specifications.component";
import { NewLicenseComponent } from "./new-license/new-license.component";
import { ChangeComponent } from "./change/change.component";
import { RenovacionesComponent } from "./renovaciones/renovaciones.component";
import { ProductResolver } from "@web/app/resolvers/product.resolver";

const routes: Routes = [
    {
        path: 'information',
        component: ProductInformationComponent,
        children: [
            {
              path: 'opciones',
              component: ProductSpecificationsComponent
            },
            {
              path: 'nuevo/:id',
              component: NewLicenseComponent,
              resolve:{
                product: ProductResolver
              }
            },
            {
              path: 'cambio',
              component: ChangeComponent
            },
            {
              path: 'renovacion',
              component: RenovacionesComponent
            },
            {
              path: '',
              pathMatch: "full",
              redirectTo:'information/opciones'
            }
          ] 
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
      ProductResolver
    ]
  })

export class ProductsRoutingModule {
}