import { Component } from "@angular/core";
import { DataDogService } from "@common/lib/services/datadog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent {
  title = "E-commerce-admin";

  constructor(
    private dd:DataDogService,
    translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.dd.initScriptDataDog()
  }

}