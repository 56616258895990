import { Injectable } from "@angular/core";
import jwtDecode from "jwt-decode";
import { ISession } from "../models/session.model";
import { IClaims } from "./../models/claims.model";
import { ShoppingCarServiceService } from "@web/app/services/shopping-car-service.service";
import { DataDogService } from "./datadog.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public static nullClaims: IClaims = {
    email: "",
    unique_name: "",
  };

  private cookieName = "session-id";
  private _token: string = "";
  private urlSession: string = "";

  constructor(private carService:ShoppingCarServiceService,private dd:DataDogService) {}

  public get token(): string {
    return (
      this._token ||
      (this._token = localStorage.getItem(this.cookieName) as string)
    );
  }

  public getURLSession(){
    return this.urlSession;
  }

  public setURLSession(url:string){
    this.urlSession = url;
  }

  public get claims(): IClaims {
    return this.token
      ? jwtDecode<IClaims>(this.token)
      : SessionService.nullClaims;
  }

  public setSession({ token, expirationTime }: ISession) {
    localStorage.setItem(this.cookieName, token);
    this._token = token;
  }

  public clearSession(): void {
    localStorage.removeItem(this.cookieName);
    localStorage.removeItem("user");
    this.dd.removeUserSession();
    this.carService.resetCart();
  }

  public get hasValidSession(): boolean {
    let value: boolean = false;
    if (localStorage.getItem(this.cookieName)) {
      value = true;
    }
    return !!this.token && value;
  }
}
