<div class="contenido">
    <div class="header-title">
        <p class="view-information">Usuarios</p>
    </div>
    <div class="recuadro">
        <div class="filtros">
            <input class="name-field" type="text" placeholder="Buscar" [formControl]="search">
            <button class="create-button" [routerLink]="['/main-page/usuarios/crear']"> + Nuevo Usuario</button>
        </div>
        <app-table [data]="userAdminData" [columns]="columns" (onClick)="tableButton($event)"></app-table>
        <div class="paginator">
            <app-paginator 
                [totalItems]="totalItems" 
                [currentIndex]="currentIndex" 
                [sizePage]="sizePage"
                (changeCurrentIndex)="changeCurrentIndex($event)">
            </app-paginator>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" [ngClass]="{'show d-block':showConfirmDelete}">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="ajuste-detalle">
                    <h5 class="modal-title">Confirmación de baja</h5>
                    <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="closeModalDelete()">
                </div>
            </div>
            <div class="modal-body">
                <button class="cancel" (click)="closeModalDelete()">Cancelar</button>
                <button class="delete" (click)="confirmDelete()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div id="modal-cambiar-contrasena" class="modal" [ngClass]="{'show d-block':showChangePassword}">
    <div class="modal-contenido ">
        <div class="modal-cabecera card-header">
            <p class="modal-titulo">Cambiar Contraseña</p>
        </div>
        <div class="modal-cuerpo">
            <form  [formGroup]="myForm" (ngSubmit)="submitForm()">
                <div class="campo">
                    <label class="modal-subtitulo" for="nombre">Nombre:</label>
                    <label class="modal-info" type="text" id="nombre" >{{userData.name}} {{userData.lastName}} </label>
                </div>
                <div class="campo">
                    <label class="modal-subtitulo" for="correo-electronico">Correo Electrónico:</label>
                    <label class="modal-info" type="text" id="nombre" >{{userData.username}} </label>
                </div>
                <div class="center">
                    <input class="generate-password-button" type="checkbox" formControlName="checkbox1">
                    <label class="modal-info2" for="checkbox1">Crear una contraseña automática</label>
                  </div>
                  <div *ngIf="myForm.get('checkbox1')?.value === false">
                      <p class="subtitulo">Las contraseñas deben contener entre 8 y 26 caracteres, y usar una combinación de al menos 3 de los siguientes elementos: letras mayúsculas, letras minúsculas, números y símbolos.</p>
                      
                      <input class="form-input" type="password" formControlName="contrasena">
                      <div *ngIf="myForm.get('contrasena')?.errors">
                        La contraseña no es válida.
                      </div>
                    </div>
                  <div class="center">
                    <input class="generate-password-button" type="checkbox" formControlName="checkbox2">
                    <label class="modal-info2" for="checkbox2">Requerir el usuario cambie su contreaseña cuando inice sesión por primera vez</label>
                  </div>
                  <div class="center">
                    <input class="generate-password-button" type="checkbox" formControlName="checkbox3">
                    <label class="modal-info2" for="checkbox3">Enviar contraseña por correo electrónico al finalizar</label>
                  </div>
                <div class="buttons">
                    <button (click)="showChangePassword=false" class="camcel-button">Cancelar</button>
                    <button type="submit" class="accept-button"(click)="verifyPassword()">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div id="modal-cambiar-contrasena" class="modal" [ngClass]="{'show d-block':showSuccessPassword}">
    <div class="modal-contenido ">
        <div class="modal-cabecera card-header">
            <p class="modal-titulo">Contraseña generada con éxito</p>
        </div>
        <div class="modal-cuerpo">
                <div class="campo">
                    <div class="row"><img src="../../../../../assets/images/check_exito.svg" alt="" style="width: 10%;"></div>
                    <div class="row"><p class="modal-subtitulo" style="text-align: center;">Te compartimos la contraseña actualizada</p></div>

                </div>
                <div class="campo buttons2">
                    <input class="form-input" type="text" [value]="generatedPassword">
                    <button (click)="copiarPortapapeles()" class="accept-button">{{copyText}}</button>
                </div>
        </div>        
                <div class="buttons2 confirm">
                    <button type="submit" [ngClass]="{'accept-button': enablePasswordButton, 'cancel-button': !enablePasswordButton}" (click)="showSuccessPassword=false;enablePasswordButton=false" [disabled]="!enablePasswordButton">Guardar</button>
                </div>
        
    </div>
</div>
