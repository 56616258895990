import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { IPurchaseDetail } from '@common/lib/models/purchase.model';
import { IBanner } from '@common/lib/models/banner.model';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() columns: any[] = [];
  @Input() isPaused:boolean = false;
  search = ""

  @Output() onClick = new EventEmitter()

  constructor(
    private searchService:GenericSearchService
  ) { }

  ngOnInit(): void {
    this.searchService.getSearchValue$().subscribe(value => {
      this.search = value;
    });
  }

  ctaClick(object:any, btn: any){
    this.onClick.emit({
      object,
      btn
    })
  }

  checkCombo(list:IPurchaseDetail[]){
    var thereIsCombo=false;
    list.forEach(product =>{
      if(product.isInCombo){
        thereIsCombo=true;
      }
    })
    return thereIsCombo;
  }

  getValue(row: any, fieldName: string){
    return (fieldName.includes("."))? 
      (
        row[fieldName.split(".")[0]]?.[fieldName.split(".")[1]]
      )
      :row[fieldName];
  }

  rowPrices: any;

  openPrices(row: any){
    this.rowPrices = row;
  }

  closeModalPrices(){
    this.rowPrices = null;
  }

  debitOrCredit(type: any){
    if(type == 'debit')
      return "Tarjeta de débito"
    if(type == 'credit')
      return "Tarjeta de crédito"

    return ""
  }
  isBanner(data:any):boolean{
    return data && typeof data === 'object' && 'paused' in data;

  }
  iBannerPaused(p:boolean,a1:number){
    let isPaused = false;
    if(p && a1 === 4){
      isPaused = true;
    }
    if(!p && a1 === 5 ){
     isPaused = false;
    }
    return isPaused;
  }
}
