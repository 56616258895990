<div class="contenido">
    <div class="recuadro">
        <div class="header-title">
            <p class="view-information">{{getTitle}}</p>
        </div>
        <div class="contenedor-formulario">
            <form [formGroup]="myForm">
                <div class="div-column">
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <label class="form-label" for="nombre">Nombre del banner *</label>
                        <input class="form-input" type="text" formControlName="nombre">
                        <div *ngIf="myForm.get('nombre')?.errors && myForm.get('nombre')?.touched">
                          El nombre es obligatorio.
                        </div>
                      </div>
                      <div>
                        <label class="form-label" for="Descripcion">Descripción (opcional)</label>
                        <textarea class="form-input"  formControlName="Descripcion" cols="30" rows="10" placeholder="Escribe una Descripcion"
                        ></textarea>
                      </div>
                      <div>
                          <label class="form-label" for="URL">URL al que va dirigido</label>
                          <input class="form-input" type="text" formControlName="URL">
                      </div>
                      <div>
                        <label class="form-label" for="date">Fecha de publicación *</label>
                        <input class="form-input"  type="date" placeholder="dd/mm/aaaa" formControlName="initialDate" >
                        <div *ngIf="myForm.get('FechaPublicacion')?.errors && myForm.get('FechaPublicacion')?.touched">
                          Este campo es obligatorio
                        </div>
                      </div>
                      <div>
                          <label class="form-label" for="date">Fecha de eliminación (opcional)</label>
                          <input class="form-input" type="date" placeholder="dd/mm/aaaa" formControlName="expirationDate" >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="banner-container">
                        <label class="form-label" for="bannerWeb">Banner Web</label>
                        <div class="form-input-file">
                          <input type="file"  accept="image/*" formControlName="bannerWeb" style="display: none"  (change)="onFileChange($event, 'web')" id="web">
                          <img [ngClass]="croppedImage ? 'banner':'icon-file'" [src]="croppedImage ? croppedImage:'../../../../../assets/images/add-icon.svg'" type="file"  (click)="openFileInput('web')">
                          <div *ngIf="!croppedImage" class="text">
                            Cargar
                            <br>
                            Banner web
                            <br>
                            PNG (1920 px X 447 px)
                        </div>
                        </div>
                      </div>
                      <div class="banner-container">
                        
                        <label class="form-label" for="bannerMobile">Banner Móvil</label>
                        <div class="form-input-file">
                          <input type="file"  accept="image/*" formControlName="bannerMobile" style="display: none"  (change)="onFileChange($event, 'mobile')" id="mobile">
                          <img [src]="croppedImageMobile ? croppedImageMobile:'../../../../../assets/images/add-icon.svg'" [ngClass]="croppedImageMobile ? 'banner':'icon-file'"  type="file"  (click)="openFileInput('mobile')">
                          <div *ngIf="!croppedImageMobile" class="text">
                            Cargar
                            <br>
                            Banner Mobile
                            <br>
                            PNG (430 px X 275 px)
                        </div>
                        </div>
                      </div>
                      
                    <div class="buttons" *ngIf="!spinnerActive">
                      <button class="accept-button" type="submit" [disabled]="myForm.invalid" (click)="onSubmit()">Guardar y Programar</button>
                      <button class="cancel-button" routerLink="/main-page/banners">Cancelar</button>
                    </div>
                    </div>
                  </div>
                   
                    
                  </div>
            </form>
            
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" [ngClass]="{'show d-block':showConfirmSave}">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="ajuste-detalle">
                    <h5 class="modal-title">Advertencia</h5>
                    <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="closeModalSave()">
                </div>
            </div>
            <div>
                ¿Confirma que la fecha límite del banner sea indefinida?
            </div>
            <div class="modal-body">
                <button class="delete" (click)="closeModalSave()">Cancelar</button>
                <button class="cancel" (click)="confirmSave()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{'show d-block':showModal}" class="modal fade bd-example-modal-xl">
  <div class="modal-dialog modal-xl">
    <div class="modal-content height-modal">
      <div class="modal-header">
        <div class="ajuste-detalle">
          <h5 class="modal-title">Imagen del banner</h5>
          <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="closeModal()">
        </div>
      </div>
      <div class="row height-modal-area">
        <div class="col-8 height-modal-area">
          <section style="height: 90%">
            <image-cropper *ngIf="imageURL" class="height-modal-area" [imageURL]="imageURL" [aspectRatio]="aspectRatio" format="png"
              (imageCropped)="onImageCropped($event)" 
              (cropperReady)="cropperReady($event)"></image-cropper>
            <div *ngIf="loading" class="loader">Loading...</div>
          </section>
        </div>
        <div class="col-4">
          <section>
            <div>
              Visualización
            </div>
            <img style="width: 100%;" [src]="(this.aspectRatio === 1920 / 467)?croppedImage:croppedImageMobile" />
          </section>
          <div class="buttons mt-3">
            <button class="accept-button" (click)="accept()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>