import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { IErrorDefinition } from '@common/lib/models/error-definition.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { SpinnerService } from '@common/lib/services/spinner.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-create-banner',
  templateUrl: './create-banner.component.html',
  styleUrls: ['./create-banner.component.less']
})
export class CreateBannerComponent implements OnInit {
  
  createMode = true;
  spinnerActive = false;
  imageURL = "";
  imageURLMobile = "";
  showConfirmSave = false;
  formData: any;
  fileImage?: File;


  @ViewChild('fileInput') fileInput!: ElementRef;

  data: any;
  myForm!: FormGroup;
  formBodyHistoric: any;
  productCategoryArray: any = [];
  productCategoriesCat1: any = [];
  productCategoriesCat2: any = [];
  idBanner = "";


  croppedImage: any = '';
  croppedImageMobile: any = '';
  loading = true;
  imageCropped:any;
  mobileBanner:any;
  webBanner:any;
  aspectRatio: number = 16 / 9; // Aspecto por defecto
  showModal = false;
  
  imageChangedEvent: any = '';
  nameFile: any = '';
  mostrarCropper = false;
  imageProduct?: Blob;
  constructor(
    private router: Router,
    private routeActive: ActivatedRoute,
    private apiService:AdminApiServiceService,
    private spinnerService:SpinnerService,
    private toastrService:ToastrMessageService,
    private toastr: ToastrService,
    private formBuilder:FormBuilder,
    private sanitizer: DomSanitizer,
  ) { 
    this.myForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      Descripcion: [''],
      URL: [''],
      initialDate:['', Validators.required],
      expirationDate:[''],
      bannerWeb:[],
      bannerMobile:[]
    });
  }

  ngOnInit(): void {
    this.idBanner = this.routeActive.snapshot.params['id'];
    if(this.idBanner){
      try{
        this.getBannerById()
      } catch{
        this.data.urlImage = ""
      }
      this.createMode=false;
    }
    
  
  }

  sendData: any;
  onSubmit(){

    let sendData = {
      Id: null,
      Name:this.myForm.controls['nombre'].value,
      Description: this.myForm.controls['Descripcion'].value,
      UrlImage:null,
      UrlRedirect: this.myForm.controls['URL'].value,
      initialDate: this.myForm.controls['initialDate'].value,
      expirationDate: this.myForm.controls['expirationDate'].value,
      isChangeImage:false
    }

    if(!sendData.expirationDate){
      this.showConfirmSave = true;
      this.sendData = sendData;
      return;
    }else{
      if(sendData.expirationDate<sendData.initialDate){
        this.toastr.warning("La fecha de eliminación no puede ser menor a la fecha de publicación",'Advertencia');
        return;
      }
    }

    this.spinnerActive = true;

    if(this.createMode){
      this.toFormData(sendData)
      this.apiService
        .createBanner(this.formData)
        .subscribe(
          (response: any) => {
            if(response.id){
              // this.toastrService.showSuccess("Producto guardado")
              this.toastr.success('Banner guardado','Acción exitosa');
              this.router.navigate(['/main-page/banners']);
            }
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.error(message,'Error');
            this.spinnerActive = false;
            // this.toastrService.showError(message)
          }
        );
    }else{
      if(this.fileImage){
        sendData.isChangeImage=true;
      }
      this.toFormData(sendData)
      this.apiService
        .updateBanner(this.formData,this.idBanner)
        .subscribe(
          (response: any) => {
            if(response.id){
              // this.toastrService.showSuccess("Producto actualizado")
              this.toastr.success('Banner actualizado','Acción exitosa');
              this.router.navigate(['/main-page/banners']);
            }
          },
          ({ message }: IErrorDefinition) => {
            // this.toastrService.showError(message)
            this.spinnerActive = false;
            this.toastr.error(message,'Error');
          }
        );
    }
  }

  getBannerById(){
    this.apiService
      .getBanner(this.idBanner)
      .subscribe(
          (banner: any) => {
            if(banner){
              banner.initialDate = banner.initialDate?.split('T')[0];
              banner.expirationDate = banner.expirationDate?.split('T')[0];
              this.data = banner;

              this.myForm.controls['nombre'].setValue(this.data.name)
              this.myForm.controls['Descripcion'].setValue(this.data.description)
              this.myForm.controls['URL'].setValue(this.data.urlRedirect)
              this.myForm.controls['initialDate'].setValue(this.data.initialDate)
              this.myForm.controls['expirationDate'].setValue(this.data.expirationDate)
                    
              if(this.data.urlImage)
                this.getImage()

              if(this.data.urlImageMobile)
                this.getImageMobile()
            }
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
  }

  onChange(formBody: any){
    // console.log("formBody",formBody)
    this.formBodyHistoric = formBody;
  }

  get getTitle(){
    return this.createMode?"Publicidad > Nuevo Banner":"Publicidad > Editar Banner";
  }

  get getSaveName(){
    return this.idBanner? "Editar y Programar":"Guardar y Programar";
  }

  uploadImage($event: any){
    if($event){
      this.fileImage = $event;
    }
  }

  // saveImage(){
  //   if(this.formData){
  //     this.apiService
  //       .uploadImage(this.formData)
  //       .subscribe(
  //         (response: any) => {
  //           if(response){
  //             // this.toastrService.showSuccess("Producto guardado")
  //             this.toastr.success('Imagen cargada','Acción exitosa');
  //           }
  //           // console.log("response",response)
  //         },
  //         ({ message }: IErrorDefinition) => {
  //           this.toastr.error(message,'Error');
  //           this.spinnerActive = false;
  //           // this.toastrService.showError(message)
  //         }
  //       ); 
  //   }
  // }

    getImage(){
      const productInfo = {
        "objectKey": this.data.id+".png",
        "subDirectory": "banners",
        "duration": 30
      };
      try {
        this.apiService
        .getImage(productInfo)
        .subscribe(
          (response: any) => {
            if(response){
              //Verificar que existe imagen
              // console.log("response",response)
              this.croppedImage = response;
            } 
          },
          ({ message }: IErrorDefinition) => {
            // this.toastr.error(message,'Error');
            this.toastrService.showError(message)
          }
        );
      } catch (error) {
        // console.log("error",error)
      }
    }

    getImageMobile(){
      const productInfo = {
        "objectKey": this.data.id+".png",
        "subDirectory": "bannersMobile",
        "duration": 30
      };
      try {
        this.apiService
        .getImage(productInfo)
        .subscribe(
          (response: any) => {
            if(response){
              //Verificar que existe imagen
              // console.log("response",response)
              this.croppedImageMobile = response;
            } 
          },
          ({ message }: IErrorDefinition) => {
            // this.toastr.error(message,'Error');
            this.toastrService.showError(message)
          }
        );
      } catch (error) {
        // console.log("error",error)
      }
    }

    closeModalSave(){
      this.showConfirmSave = false;
    }

    confirmSave(){
      this.closeModalSave()
      this.spinnerActive = true;
      let sendData = {
        Id: null,
        Name:this.myForm.controls['nombre'].value,
        Description: this.myForm.controls['Descripcion'].value,
        UrlImage:null,
        UrlRedirect: this.myForm.controls['URL'].value,
        InitialDate: this.myForm.controls['initialDate'].value,
        ExpirationDate: this.myForm.controls['expirationDate'].value,
        // FileUpload:this.webBanner,
        // FileUploadMobile:this.mobileBanner,
        isChangeImage:false
      }

      if(this.createMode){
        this.toFormData(sendData)
        console.log(this.formData)
        this.apiService
          .createBanner(this.formData)
          .subscribe(
            (response: any) => {
              if(response.id){
                // this.toastrService.showSuccess("Producto guardado")
                this.toastr.success('Banner guardado','Acción exitosa');
                this.router.navigate(['/main-page/banners']);
              }
            },
            ({ message }: IErrorDefinition) => {
              this.toastr.error(message,'Error');
              this.spinnerActive = false;
              // this.toastrService.showError(message)
            }
          );
      }else{
        if(this.fileImage){
          sendData.isChangeImage=true;
        }
        this.toFormData(sendData)
        this.apiService
          .updateBanner(this.formData,this.idBanner)
          .subscribe(
            (response: any) => {
              if(response.id){
                // this.toastrService.showSuccess("Producto actualizado")
                this.toastr.success('Banner actualizado','Acción exitosa');
                this.router.navigate(['/main-page/banners']);
              }
            },
            ({ message }: IErrorDefinition) => {
              // this.toastrService.showError(message)
              this.spinnerActive = false;
              this.toastr.error(message,'Error');
            }
          );
      }
    }

    toFormData(sendData: any){
      this.formData = new FormData();
      if(sendData){
       
        // if(this.webBanner){
          
        //   const url = this.webBanner.changingThisBreaksApplicationSecurity
        //   fetch(url)
        //     .then(response => response.blob())
        //     .then(blob => {
        //       const file = new File([blob], 'imagen.png', { type: blob.type });
        //       this.formData.append(this.capitalizarPrimeraLetra('fileUpload'), file, 'imagen.png');
        //       console.log('Archivo añadido al FormData:', this.formData);
        //     })
        //     .catch(error => console.error('Error al convertir SafeUrl a Blob:', error));
          
        // }
        if(this.webBanner)
          this.formData.append(this.capitalizarPrimeraLetra('fileUpload'), this.webBanner, "imagen_web.png");
        if(this.mobileBanner)
          this.formData.append(this.capitalizarPrimeraLetra('fileUploadMobile'), this.mobileBanner, "imagen_mobile.png");
        // if(this.mobileBanner){
        //   let file:any;
        //   const url = this.mobileBanner.changingThisBreaksApplicationSecurity
        //   fetch(url)
        //     .then(response => response.blob())
        //     .then(blob => {
        //       const file = new File([blob], 'imagen.png', { type: blob.type });
        //       this.formData.append(this.capitalizarPrimeraLetra('fileUploadMobile'), file, 'imagen.png');
        //       console.log('Archivo añadido al FormData:', this.formData);
        //     })
        //     .catch(error => console.error('Error al convertir SafeUrl a Blob:', error));
        // }
        for (const key in sendData) {
          if (Object.prototype.hasOwnProperty.call(sendData, key)) {
            this.formData.append(this.capitalizarPrimeraLetra(key), sendData[key]);
          }
        }
        console.log("this.formData",this.formData,sendData)
      }
    }

    capitalizarPrimeraLetra(palabra: string): string {
      if (palabra.length === 0) {
          return palabra;
      }
      return palabra.charAt(0).toUpperCase() + palabra.slice(1);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (this.aspectRatio === 1920 / 467)
      this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    else
      this.croppedImageMobile = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    this.imageProduct = event.blob!;
  }

  crop() {
      if (this.aspectRatio === 1920 / 467) {
      this.webBanner = this.imageProduct;
    } else {
      this.mobileBanner = this.imageProduct;
    }
    
    
    this.closeModal();
  }
  closeModal() {
    this.showModal = false;
  }
  onFileChange(event: any, type: string) {
    this.showModal = true;
    this.imageChangedEvent = event;
    this.aspectRatio = type === 'web' ? 1920 / 467 : 430 / 275;
    const file = event.target.files && event.target.files[0];

    if (file) {
      this.readImage(file);
    }
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.loading = false;
    
  }
  accept() {
    console.log('Cropped Image:', this.croppedImage);
    if (this.croppedImage) {
      this.imageURL = this.croppedImage;
      this.uploadImage(this.imageURL)
      this.crop();
    } else {
      console.error('No image cropped');
      this.toastr.error('No se ha recortado ninguna imagen.', 'Error');
    }
  }
  
  openFileInput(type:string): void {
    let fileInput
    if(type === "web"){
      fileInput = <HTMLInputElement>document.getElementById("web")
    }
    else if(type == "mobile"){
      fileInput = <HTMLInputElement>document.getElementById("mobile")
    }
    if(fileInput){
      fileInput.click();
    }
    
   
  }
  onFileSelected(event: any): void {
    const file = event.target.files && event.target.files[0];

    if (file) {
      this.readImage(file);
    }
  }
  
  onSelectImage(imageData: string): void {
    this.imageURL = imageData;
    this.mostrarCropper = true;
  
    // Crear un elemento <img> para verificar la imagen
    const imgElement = new Image();
    imgElement.src = imageData;
  
    // Manejar el evento de error
    imgElement.onerror = (error) => {
      console.error('Error al cargar la imagen:', error);
      this.toastr.error('Error al cargar la imagen. Por favor, intenta con otra imagen.', 'Error');
    };
  
    imgElement.onload = () => {
      console.log('Imagen cargada correctamente');
    };
  }
  

  private readImage(file: File): void {
    this.imageChangedEvent = file;
    this.nameFile = file.name;
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageData = e.target?.result as string;
      this.onSelectImage(imageData);

      // Verificar si la imagen se carga correctamente
      const imgElement = new Image();
      imgElement.src = imageData;

      imgElement.onerror = (error) => {
        console.error('Error al cargar la imagen:', error);
        this.toastr.error('Error al cargar la imagen. Por favor, intenta con otra imagen.', 'Error');
      };

      imgElement.onload = () => {
        console.log('Imagen cargada correctamente');
      };
    };

    reader.readAsDataURL(file);
  }

}
