<section>
    <div class="contenido">
        <!-- <p class="titulo">{{name}}</p> -->
        <p class="color-white">Admin</p>
        <div class="division"></div>
        <button [ngClass]="{'activated-button': activateOption('/main-page/usuarios')}" [routerLink]="['/main-page/usuarios']">Usuarios</button>
        <div class="division"></div>
        <button [ngClass]="{'activated-button': activateOption('/main-page/ventas')}" [routerLink]="['/main-page/ventas']">Ventas</button>
        <div class="division"></div>
        <button [ngClass]="{'activated-button': activateOption('/main-page/clientes')}" [routerLink]="['/main-page/clientes']">Clientes</button>
        <div class="division"></div>
        <button [ngClass]="{'activated-button': activateOption('/main-page/productos')}" [routerLink]="['/main-page/productos']">Productos</button>
        <div class="division"></div>
        <button [ngClass]="{'activated-button': activateOption('/main-page/banners')}" [routerLink]="['/main-page/banners']">Publicidad</button>
        <div class="division"></div>
    </div>
</section>
