import { Component, ChangeDetectionStrategy, ViewEncapsulation, AfterContentInit, Input, TemplateRef, ContentChildren, QueryList, Output, EventEmitter } from "@angular/core";
import { PrimeTemplate } from "primeng/api";

@Component({
    selector: 'app-button',
    templateUrl: "./button.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./button.component.less"],
})
export class ButtonComponent implements AfterContentInit {

    @Input() type: string = "button";

    @Input() iconPos: string = 'left';

    @Input() icon!: string;

    @Input() badge!: string;

    @Input() label!: string;

    @Input() disabled!: boolean;

    @Input() loading: boolean = false;

    @Input() loadingIcon: string = "pi pi-spinner pi-spin";

    @Input() style: any;

    @Input('class') styleClass!: string;


    @Input() badgeClass!: string;

    @Input() ariaLabel!: string;

    contentTemplate!: TemplateRef<any>;

    @ContentChildren(PrimeTemplate) templates!: QueryList<any>;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onFocus: EventEmitter<any> = new EventEmitter();

    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch(item.getType()) {
                case 'content':
                    this.contentTemplate = item.template;
                break;

                default:
                    this.contentTemplate = item.template;
                break;
            }
        });
    }

    badgeStyleClass() {
        return {
            'app-badge app-component': true,
            'app-badge-no-gutter': this.badge && String(this.badge).length === 1
        }
    }
}