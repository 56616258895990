export class Filter {
	search!:string;
	initialDate!: string;
	finalDate!: string;
	todo!: boolean;
	certificado!: boolean;
	pdf!:boolean;
	xml!:boolean;
}

export class FlagFilter{
	todo!: boolean;
	certificado!: boolean;
	pdf!:boolean;
	xml!:boolean;
}