<div class="contenido">
    <div class="header-title">
        <p class="view-information">Ventas > Detalle de venta</p>
    </div>
    <div  class="recuadro">
        <div class="filtros" [formGroup]="formularioFiltros">
            <input class="buscar" type="text" placeholder="Buscar" formControlName="search" (input)="onInputChange($event)">
            <input class="fecha" type="date" formControlName="initialDate"> 
            <input class="fecha" type="date" formControlName="finalDate">
            <div class="estatus" (click)="abrirSelector()">
                <p>Filtrar por estatus</p>
                <div class="triangulo"></div>
            </div>
        </div>
        <div class="selectores" [ngClass]="{'invisible': !mostrarSelector}">
            <ul>
                <li (click)="seleccionarFiltro('todo')"><form-check-box [selected]="banderaFiltros['todo']"></form-check-box><p>Mostrar todo</p></li>
                <li (click)="seleccionarFiltro('certificado')"><form-check-box [selected]="banderaFiltros['certificado']"></form-check-box><p>Certificados faltantes</p></li>
                <li (click)="seleccionarFiltro('pdf')"><form-check-box [selected]="banderaFiltros['pdf']"></form-check-box><p>PDF faltantes</p></li>
                <li (click)="seleccionarFiltro('xml')"><form-check-box [selected]="banderaFiltros['xml']"></form-check-box><p>XML faltantes</p></li>
            </ul>
        </div>
 <!--
        <app-table [data]="purchasesData" [columns]="columns" (onClick)="tableButton($event)"></app-table>
 -->
        
        <div class="table-container">
            <table class="table table-striped">
              <thead class="posicion-encabezado">
                <tr>
                  <th *ngFor="let column of columns" class="center">{{ column.title }}</th>
                </tr>
              </thead>
              <tbody>
                    <tr *ngFor="let purchase of purchasesData">
                        <td class="center">{{purchase.folio}}</td>
                        <td class="center">{{purchase.date.split('T')[0]}}</td>
                        <td class="center">
                            <p class="name center">
                                {{purchase.user.name}} {{purchase.user.lastName}}
                            </p>
                        </td>
                        <td class="center">
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">

                                <div *ngIf="!detail.isInCombo && detail.purchaseType">
                                    <p>{{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                                <div *ngIf="!detail.purchaseType">
                                    <p>{{detail.quantity}} {{detail.name}}</p>
                                    
                                </div>
                            </div>
                            <p class="negritas" *ngIf="checkCombo(purchase.details)">COMBO</p>
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">
                                <div *ngIf="detail.isInCombo" class="tab-combo-content">
                                    <p> {{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                            </div>
                        </td>
                        <td class="center">{{debitOrCredit(purchase.paymentMethod)}}</td>
                        <td class="campo-certificado fecha">
                            <ng-container >
                                <ng-container *ngIf="purchase.certificado == false">
                                  <div class="status">
                                    <img class="icon" src="assets/images/uncheck.svg" alt="">
                                  </div>
                            </ng-container>
                            <ng-container *ngIf="purchase.certificado  == true">
                                  <div class="status">
                                    <img class="icon" src="assets/images/check.svg" alt="">
                                  </div>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td class="campo-pdf fecha">
                            <ng-container >
                                <ng-container *ngIf="purchase.facturadoPDF == false">
                                  <div class="status">
                                    <img class="icon" src="assets/images/uncheck.svg" alt="">
                                  </div>
                            </ng-container>
                            <ng-container *ngIf="purchase.facturadoPDF  == true">
                                  <div class="status">
                                    <img class="icon" src="assets/images/check.svg" alt="">
                                  </div>
                                </ng-container>
                            </ng-container>
                            
                        </td>
                        <td class="campo-xml fecha">
                            <ng-container >
                                <ng-container *ngIf="purchase.facturadoXML == false">
                                  <div class="status">
                                    <img class="icon" src="assets/images/uncheck.svg" alt="">
                                  </div>
                            </ng-container>
                            <ng-container *ngIf="purchase.facturadoXML  == true">
                                  <div class="status">
                                    <img class="icon" src="assets/images/check.svg" alt="">
                                  </div>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td  class="campo-xml fecha"> 
                            <div class="status">
                                <img class="icon" (click)="showDetails(purchase)"  src="assets/images/details.svg">
                            </div>
                            
                        </td>
                    </tr>
              </tbody>
            </table>
          </div> <!--
        <table class="ajuste-tabla">
            <thead class="color-fondo">
                <tr>
                    <th class="campo-detalle fecha" scope="col">Ver Detalle</th>
                    <th class="campo-fecha fecha">Fecha</th>
                    <th class="campo-nombre">Nombre</th>
                    <th class="campo-productos">Productos</th>
                    <th class="campo-metodo">Método de Pago</th>
                    <th class="campo-certificado fecha">Certificado</th>
                    <th class="campo-pdf fecha">PDF</th>
                    <th class="campo-xml fecha">XML</th>
                </tr>
            </thead>
        </table>
        <div class="contenedor-tabla">
            <table class="table table-striped">
                <tbody *ngFor="let purchase of purchasesData| filterSale: filtros; trackBy: trackPurchase">
                    <tr>
                        <td class="campo-detalle fecha"><img (click)="abrir(purchase.user.name+' '+purchase.user.lastName, 'FESE192390NH3', purchase.date, purchase.details, purchase.id, purchase, purchase.facturadoPDF, purchase.facturadoXML)" class="descargar" src="../../../../../assets/images/ver detalle_icon.svg" alt=""></td>
                        <td class="campo-fecha fecha">{{purchase.date.split('T')[0]}}</td>
                        <td class="campo-nombre">{{purchase.user.name}} {{purchase.user.lastName}}</td>
                        <td class="campo-productos">
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">
                                <div *ngIf="!detail.isInCombo">
                                    <p>CONTPAQi {{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                            </div>
                            <p class="negritas" *ngIf="checkCombo(purchase.details)">COMBO</p>
                            <div *ngFor="let detail of purchase.details; trackBy: trackDetails">
                                <div *ngIf="detail.isInCombo" class="tab-combo-content">
                                    <p>CONTPAQi {{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                </div>
                            </div>
                        </td>
                        <td class="campo-metodo"><p>Tarjeta: --{{purchase.card[purchase.card.length-2]}}{{purchase.card[purchase.card.length-1]}}</p></td>
                        <td class="campo-certificado fecha">
                            <div class="acomodo">
                                <div class="boton-carga" [ngClass]="purchase.certificado ? 'cargado' : 'no-cargado'">{{getContenidoBoton(purchase.certificado)}}</div>
                           </div>
                        </td>
                        <td class="campo-pdf fecha">
                            <div class="acomodo">
                                <div class="boton-carga" [ngClass]="purchase.facturadoPDF ? 'cargado' : 'no-cargado'">{{getContenidoBoton(purchase.facturadoPDF)}}</div>
                            </div>
                        </td>
                        <td class="campo-xml fecha">
                            <div class="acomodo">
                                <div class="boton-carga" [ngClass]="purchase.facturadoXML ? 'cargado' : 'no-cargado'">{{getContenidoBoton(purchase.facturadoXML)}}</div>
                            </div>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div> -->
        
    </div>
</div>

<!-- Modal detalles -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarDetalles}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="padding-bottom: 0px;">
        <div class="modal-header">
            <div class="ajuste-detalle">
                <h5 class="modal-title">Detalle de Venta</h5>
                <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="cerrar()">
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="detalle-compra margen-detalle">
                    <p>Nombre: {{nombre}}</p>
                    <p>RFC: {{rfc}}</p>
                </div>
            </div>
            <div class="col-6">
                <div class="detalle-compra">
                    <p>Fecha: {{fecha.split('T')[0]}}</p>
                    <p>Status: Pagado</p>
                </div>
            </div>
        </div>
        <div class="row" style="background-color: #DCDCDC;">
            <h5 class="modal-title ajuste-factura">Factura</h5>
        </div>
        <div class="row" style="background-color: #DCDCDC;">
            <div class="col-6">
                <section class="boton">
                    <div class="file-button">
                        <input id="file-pdf" type="file" name="file-pdf" (change)="setFile($event, 'PDF', '')" accept="application/pdf" #fileInputPDF >
                        <button mat-raised-button color="primary" (click)="fileInputPDF.click()" class="boton-pdf" [disabled]="loadingPDF">
                            <div class="spinner-border" role="status" *ngIf="loadingPDF">
                                <span class="sr-only"></span>
                            </div>
                            <ng-container *ngIf="!loadingPDF">
                                Subir PDF
                            </ng-container>
                        </button>
                    </div>
                    <div class="iconos">
                        <p class="informacion-archivo">{{facturadoPDF?"Cargado":"No Cargado"}}</p>
                        <img src="../../../../../assets/images/Botecito_eliminar.svg" alt="" class="icono-borrar" (click)="deleteFile(PurchaseId, 'facturas', '.pdf')" [ngClass]="{'ocultar':!facturadoPDF}" >
                        <img src="../../../../../assets/images/ver detalle_icon.svg" alt="" class="icono-ver" (click)="redirigirFacturaPDF(facturadoPDF)" [ngClass]="{'ocultar':!facturadoPDF}">
                    </div>
                </section>
            </div>
            <div class="col-6">
                <section class="boton">
                    <div class="file-button">
                        <input id="file" type="file" name="file" (change)="setFile($event, 'XML', '')" accept="application/xml, text/xml" #fileInputXML >
                        <button mat-raised-button color="primary" (click)="fileInputXML.click()" class="boton-xml" [disabled]="loadingXML">
                            <div class="spinner-border" role="status" *ngIf="loadingXML">
                                <span class="sr-only"></span>
                            </div>
                            <ng-container *ngIf="!loadingXML">
                                Subir XML
                            </ng-container>
                        </button>
                    </div>
                    <div class="iconos">
                        <p class="informacion-archivo">{{facturadoXML?"Cargado":"No Cargado"}}</p>
                        <img src="../../../../../assets/images/Botecito_eliminar.svg" alt="" class="icono-borrar" (click)="deleteFile(PurchaseId, 'xml', '.xml')" [ngClass]="{'ocultar':!facturadoXML}">
                        <img src="../../../../../assets/images/ver detalle_icon.svg" alt="" class="icono-ver" (click)="redirigirFacturaXML(facturadoXML)" [ngClass]="{'ocultar':!facturadoXML}">
                    </div>
                </section>
            </div>
        </div>
        <div class="d-flex">
            <button
            class="info-button ajuste-desglose-producto"
            type="submit"
            (click)="mostrarDetallesProducto()"
        >
        {{detallesProductoMensaje}}
        <img [src]=" detallesProductoVisible ? '../../../../../assets/images/arrow-up.svg':'../../../../../assets/images/arrow-down.svg'" ></button>
        <button
            class="info-button ajuste-desglose-detalles"
            type="submit"
            (click)="mostrarDeslgoseCompra()"
        >{{desgloseCompra}}
        <img [src]=" desgloseCompraVisible ? '../../../../../assets/images/arrow-up.svg':'../../../../../assets/images/arrow-down.svg'" ></button>
        </div>
        
        <section class="detalles" *ngIf="detallesProductoVisible">
            <div class="row">
                <div class="col-6">
                    <h5 class="modal-title" *ngIf="checkProducts(purchaseDetail)">Productos</h5>
                </div>
                <div class="col-6" style="text-align: center;">
                    <h5 class="modal-title" *ngIf="checkProducts(purchaseDetail)">Vigencia:</h5>
                </div>
            </div>
            <div *ngFor="let detail of purchaseDetail; trackBy: trackDetails">
                <div *ngIf="!detail.isInCombo">
                    <div class="detalle-productos">
                        <div class="producto">
                            <p>{{detail.name}} {{(detail?.product?.productType == 'CONTPAQi')? getLicenciamiento(detail.isMono): ''}}</p>
                            <p *ngIf="!detail.isInCombo && detail.purchaseType">{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                            <p *ngIf="detail.license">Licencia: {{detail.license}}</p>
                            <p>{{detail.license}}</p>
                            <p *ngIf="detail?.metadata && detail?.product?.productType != 'XML_SAT'">{{(detail?.product?.productType == 'XML_SAT')? 'Imei: ':'Email: '}} {{detail?.metadata}}</p>
                        </div>
                        <div class="precio">
                            <div *ngIf="detail?.product?.productType != 'XML_SAT'">
                                <div *ngIf="!detail.certificado; else archivo_cargado" class="file-button">
                                    <div *ngIf="detail?.product?.productType == 'XML_SAT' && !detail?.metadata">
                                        <input type="text" >
                                    </div>
                                    <input id="file" type="file" name="file" (change)="setFile($event, 'Certificado', detail.id)" accept="application/pdf" #fileInputCertificado >
                                    <button mat-raised-button color="primary" (click)="fileInputCertificado.click()" class="boton-certificado" [disabled]="loadingCertificate" style="width: 175px;">
                                        <div class="spinner-border" role="status" *ngIf="loadingCertificate">
                                            <span class="sr-only"></span>
                                        </div>
                                        <ng-container *ngIf="!loadingCertificate">
                                            Cargar Certificado
                                        </ng-container>
                                    </button>
                                </div>
                                <ng-template #archivo_cargado class="iconos">
                                    <p class="informacion-archivo ajuste-certificado">Cargado</p>
                                    <img src="../../../../../assets/images/Botecito_eliminar.svg" alt="" class="icono-borrar" (click)="deleteFile(detail.id, 'certificados', '.pdf')">
                                    <img src="../../../../../assets/images/ver detalle_icon.svg" alt="" class="icono-ver" (click)="redirigirCertificado(detail.certificado, detail.id)">
                                </ng-template>
                            </div>
                            <div *ngIf="detail?.product?.productType == 'XML_SAT'" class="IMEI-container">
                                <input type="text" id="IMEI" [value]="detail?.metadata" class="IMEI-input" [disabled]="!editable">
                                <div *ngIf="detail?.metadata; else setIMEI" class="d-flex">
                                    <img src="../../../../../assets/images/EDIT_IMEI.svg" alt="" class="icono-editar" *ngIf="!editable" (click)="editarIMEI()">
                                    <img src="../../../../../assets/images/TERMINAR-edicion-IMEI.svg" alt="" class="icono-editar" *ngIf="editable" (click)="updateIMEI(detail.id)">
                                    <img src="../../../../../assets/images/CANCELAR.svg" alt="" class="icono-editar"*ngIf="editable" (click)="editarIMEI()">
                                </div>
                                <ng-template #setIMEI class="IMEI-input">
                                    <img src="../../../../../assets/TERMINAR-edicion-IMEI.svg" alt="" class="icono-editar"  (click)="updateIMEI(detail.id)">
                                    <img src="../../../../../assets/images/CANCELAR.svg" alt="" class="icono-editar" (click)="editarIMEI()">
                                </ng-template>
                            </div>

                            <div class="date-certificate">
                                <input type="date" [(ngModel)]="detail.effectiveDate" (change)="setDateCertificateFromInput(detail.effectiveDate,detail.id)" style="height: 45px; margin-bottom: 10px; width: 150px;">
                            </div>
                            <form-switch style=" margin-bottom: 10px;" (onChangeElement)="((!$event?.value)?detail.effectiveDate='':{});setDateCertificate($event,detail.effectiveDate,detail.id)"
                            [disabled]="!detail.effectiveDate" (click)="activeEfectiveDate(detail.effectiveDate)"
                            [rightLabel]="'Usuarios'" [value]="detail.effectiveDate"></form-switch>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h5 class="modal-title" *ngIf="checkCombo(purchaseDetail)">Combo</h5>
                </div>
                <div class="col-6" style="text-align: center;">
                    <h5 class="modal-title" *ngIf="checkCombo(purchaseDetail)">Vigencia:</h5>
                </div>
            </div>
            <div class="margin-bottom" *ngFor="let detail of purchaseDetail; trackBy: trackDetails">
                <div *ngIf="detail.isInCombo">
                    <div class="detalle-productos">
                        <div class="producto">
                            <p>{{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                            <p  >{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                            <p *ngIf="detail.license">Licencia: {{detail.license}}</p>
                        </div>
                        <div class="precio">
                            <div *ngIf="!detail.certificado; else archivo_cargado" class="file-button">
                                <input id="file" type="file" name="file" (change)="setFile($event, 'Certificado', detail.id)" accept="application/pdf" #fileInputCertificado >
                                <button mat-raised-button color="primary" (click)="fileInputCertificado.click()" class="boton-certificado" style="width: 175px;">
                                    <div class="spinner-border" role="status" *ngIf="loadingCertificate">
                                        <span class="sr-only"></span>
                                    </div>
                                    <ng-container *ngIf="!loadingCertificate">
                                        Cargar Certificado
                                    </ng-container>
                                </button>
                            </div>
                            <ng-template #archivo_cargado class="iconos">
                                <p class="informacion-archivo ajuste-certificado">Cargado</p>
                                <img src="../../../../../assets/images/Botecito_eliminar.svg" alt="" class="icono-borrar" (click)="deleteFile(detail.id, 'certificados', '.pdf')">
                                <img src="../../../../../assets/images/ver detalle_icon.svg" alt="" class="icono-ver" (click)="redirigirCertificado(detail.certificado, detail.id)">
                            </ng-template>
                            <div class="date-certificate">
                                <input type="date" [(ngModel)]="detail.effectiveDate" (change)="setDateCertificateFromInput(detail.effectiveDate,detail.id)" style="height: 45px; margin-bottom: 10px; width: 150px;">
                            </div>
                            <form-switch style=" margin-bottom: 10px;" (onChangeElement)="((!$event?.value)?detail.effectiveDate='':{});setDateCertificate($event,detail.effectiveDate,detail.id)"
                            [disabled]="!detail.effectiveDate" (click)="activeEfectiveDate(detail.effectiveDate)"
                            [rightLabel]="'Usuarios'" [value]="detail.effectiveDate"></form-switch>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <app-button
                class="full-width app-button-rounded app-button-danger hover-button ajuste-desglose"
                [label]="desgloseMensaje"
                type="submit"
                (onClick)="mostrarDesglose()"
            ></app-button> -->
            <!-- /////////////////////////////////////////////////////////////////////////////////////////// -->
             </section>
            <section class="detalles" *ngIf="desgloseCompraVisible">
            <div class="desglose">
                <ng-container>
                    <h5 class="modal-title desglose-title">Desglose de la compra</h5>
                    <h5 class="modal-title" *ngIf="checkProducts(purchaseDetail)">Productos</h5>
                    <div *ngFor="let detail of purchaseDetail; trackBy: trackDetails">
                        <div *ngIf="!detail.isInCombo">
                            <div class="detalle-productos">
                                <div class="producto">
                                    <p>{{ detail.quantity + ' '}}{{detail.name}} {{(detail?.product?.productType == 'CONTPAQi')? getLicenciamiento(detail.isMono): ''}}</p>
                                    <p *ngIf="!detail.isInCombo && detail.purchaseType">{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                    <p *ngIf="detail.license">Licencia: {{detail.license}}</p>
                                </div>
                                <div class="precio">
                                    <p>{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost))) * detail.quantity
                                        ) | currency}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="modal-title" *ngIf="checkCombo(purchaseDetail)">Combo</h5>
                    <div *ngFor="let detail of purchaseDetail; trackBy: trackDetails">
                        <div *ngIf="detail.isInCombo">
                            <div class="detalle-productos">
                                <div class="producto">
                                    <p>{{detail.name}} {{getLicenciamiento(detail.isMono)}}</p>
                                    <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
                                    <p *ngIf="detail.license">Licencia: {{detail.license}}</p>
                                </div>
                                <div class="precio">
                                    <p>{{(
                                        (detail.unitPrice + ((detail.users - 1) * (detail.usersCost))) * detail.quantity
                                        ) | currency}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="linea-divisora"></div>
                </ng-container>
            </div>
            <!-- /////////////////////////////////////////////////////////////////////////////////////////// -->
        </section>
        <div class="detalle-total">
            <div class="etiquetas">
                <p>Subtotal:</p>
                <p>Descuento:</p>
                <p *ngIf="monthly">Comisión {{bank}} Pago a {{months}} Meses:</p>
                <p>IVA:</p>
                <p class="negritas">Total:</p>
            </div>
            <div class="valores">
                <p>{{subtotal - comission | currency}}</p>
                <p>{{descuento | currency}}</p>
                <p *ngIf="monthly">{{comission | currency}}</p>
                <p>{{iva | currency}}</p>
                <p class="negritas">{{total | currency}}</p>
            </div>
        </div>
        <div class="row" style="background-color: #DCDCDC; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px;">
            <section class="boton boton-centrado">
                <app-button
                    class="full-width app-button-rounded app-button-danger hover-button ajuste-finalizar"
                    [label]="'Finalizar' | translate"
                    type="submit"
                    (onClick)="cerrar()"
                ></app-button>
            </section>
        </div>
      </div>
    </div>
</div>

<!-- Modal detalles -->

<!-- <div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarCarga}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <div class="ajuste-detalle">
                <h5 class="modal-title">Cargar {{tipoArchivo}}</h5>
            </div>
        </div>
        <section class="file-information">
            <div class="file-button">
                <input id="file" type="file" name="file" (change)="setFile($event)" #fileInput >
                <button mat-raised-button color="primary" (click)="fileInput.click()">
                    {{(!file) ? 'Seleccionar Archivo' : 'Archivo: ' + file.name }}
                </button>
                <app-button
                    class="full-width app-button-rounded app-button-secondary hover-button ajuste-pdf"
                    [label]="'Cargar' | translate"
                    type="submit"
                    (onClick)="sendFile()"
                ></app-button>
            </div>
            <div [ngClass]="{'warning':!mostrarWarning}">
                <p class="warning-p">{{message}}</p>
            </div>
        </section>
        <app-button
            class="full-width app-button-rounded app-button-secondary hover-button"
            [label]="'Aceptar' | translate"
            type="submit"
            (onClick)="cerrarCarga()"
        ></app-button>
      </div>
    </div>
</div> -->
    
   

