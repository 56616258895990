<div class="container-paginator">
  <div class="component-paginator">
    <div class="go-back" (click)="goToBack()">
      <img src="../../../../../assets/images/go-back.svg" alt="Anterior" title="Anterior">
    </div>
    <ng-container *ngFor="let index of indexPages">
      <div class="index" [ngClass]="{'index-selected':currentIndex == index}" (click)="onChangeCurrentIndex(index)">
        {{index}}
      </div>
    </ng-container>
    <div class="go-next" (click)="goToNext()">
      <img src="../../../../../assets/images/go-next.svg" alt="Siguiente" title="Siguiente">
    </div>
  </div>
</div>