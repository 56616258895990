import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseData } from '@common/lib/models/purchase.model';
import { Filter } from '@common/lib/classes/filter'; 

@Pipe({
  name: 'filterSale'
})
export class FilterSalePipe implements PipeTransform {

  transform(sales: IPurchaseData[], filter:Filter): IPurchaseData[] {
    if (!sales) return[]

    const initialDate = new Date(filter['initialDate'])
    const finalDate = new Date(filter['finalDate'])

    if(filter['search']=="" && filter["todo"]){
      return sales
    }else{
      filter['search'] = filter['search'].toLocaleLowerCase();
 
      var name=""
      return sales.filter( sale=>{
        name = sale.user.name.toLocaleLowerCase() +" "+sale.user.lastName.toLocaleLowerCase()

        if(filter["certificado"] && sale.certificado){
          return false
        }

        if(filter["pdf"] && sale.facturadoPDF){
          return false
        }

        if(filter["xml"] && sale.facturadoXML){
          return false
        }

        if(filter["initialDate"] && filter["finalDate"]){
          if(new Date(sale.date) <= initialDate|| new Date(sale.date) >= finalDate){
            return false
          }
        }

        if(!name.includes(filter['search'])){
          return false
        } 
        return true
      });
    }
    
  }

}
