<div class="cuerpo">
  <div class="go-back">
    <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()" id="imgCONTPAQi">
</div>
    <lib-product-card (tipoLicenciaSeleccionada)="actualizarSeleccion($event)" (nombreProducto)="getNombre($event)" ></lib-product-card>
    
      <div *ngIf="tipoSeleccionado === 'nuevo'">
        <app-new-license  [nombreProducto]="nombrePadre"></app-new-license>
      </div>
      <div *ngIf="tipoSeleccionado === 'renovacion'">
          <app-renovaciones></app-renovaciones>
        </div>
        <div *ngIf="tipoSeleccionado === 'cambio'">
          <app-change></app-change>
        </div>
</div>