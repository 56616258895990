<div class="contenido">
    <div class="header-title">
        <p class="view-information">Admin > Clientes</p>
    </div>
    <div  class="recuadro">
        <div class="filtros">
            <input class="buscar" type="text" placeholder="Buscar Contacto o Empresa" [formControl]="search">
        </div>
        <app-table [data]="clientsData" [columns]="columns" (onClick)="tableButton($event)"></app-table>
        <!-- <div class="contenedor-tabla">
            
            <table class="table table-striped"> 
                <thead class="posicion-encabezado">
                    <tr>
                        <th class="campo-contacto">Contacto</th>
                        <th class="campo-empresa">Razón Social</th>
                        <th class="campo-correo">Correo Electrónico</th>
                        <th class="campo-rfc">RFC</th>
                        <th class="campo-csf fecha">CSF</th>
                        <th class="campo-detalle fecha">Ver Detalle</th>
                    </tr>
                </thead>
                <tbody *ngFor="let client of clientsData | filterClient: busqueda; trackBy: trackClient">
                    <tr>
                        <td class="campo-contacto">
                            <p class="ajuste-p">{{client.name}} {{client.lastName}}</p>
                        </td>
                        <td class="campo-empresa">
                            <p class="ajuste-p">{{client.razon}}</p>
                        </td>
                        <td class="campo-correo">
                            <p class="ajuste-p">{{client.username}}</p>
                        </td>
                        <td class="campo-rfc">
                            <p class="ajuste-p">{{client.rfc}}</p>
                        </td>
                        <td class="campo-csf">
                            <div class="centrado">
                                <img (click)="redirigir(client.certificado, client.id)" class="descargar" [src]="getIcon(client.certificado)" alt=""> 
                            </div>
                        </td> 
                        <td  class="campo-detalle ajuste-col">
                            <div class="centrado">
                                <img (click)="abrir(client.id, client.certificado,client.name, client.lastName, client.razon, client.rfc, client.calle, client.numero_interior, client.numero_exterior, client.estado, client.regimen, client.phone, client.username, client)" class="descargar" src="../../../../../assets/images/ver detalle_icon.svg" alt="">
                            </div>
                        </td> 
                    </tr>
                </tbody>
            </table>

        </div> -->
        
    </div>
</div>

<!-- Modal detalles -->

<div class="modal fade bd-example-modal-lg" [ngClass]="{'show d-block':mostrarDetalles}" id="abrirReporte"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <div class="ajuste-detalle">
                <h5 class="modal-title">Detalle de Cliente</h5>
                <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="cerrar()">
            </div>
        </div>
        <section class="detalles">
            <div class="detalle-compra margen-detalle">
                <p><span class="negritas">Contacto:</span> {{contacto}}</p>
                <p><span class="negritas">Teléfono:</span> {{telefono}}</p>
                <p><span class="negritas">Correo Electrónico:</span> {{correo}}</p>
                <p><span class="negritas">Razón Social:</span> {{empresa}}</p>
                <p><span class="negritas">RFC:</span> {{rfc}}</p>
                <p><span class="negritas">Domicilio Fiscal:</span> {{domicilio}}</p>
                <p><span class="negritas">Régimen Fiscal:</span> {{regimen}}</p>
            </div>
        </section>
        <section class="botones">
            <app-button
                class="full-width app-button-rounded app-button-secondary hover-button ajuste-descarga"
                [label]="'Descargar Constancia de Situación Fiscal' | translate"
                (onClick)="redirigir(certificado, rfc!)"
            ></app-button>
        </section>
      </div>
    </div>
</div>