import { NgModule } from "@angular/core";
import { SithecTranslateModule } from "../translate/sithec-translate.module";
import { ErrorPageRoutingModule } from "./error-page-routing.module";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { NotFoundComponent } from "./not-found/not-found.component";

@NgModule({
    declarations: [
        ForbiddenComponent,
        NotFoundComponent
    ],
    imports: [
        ErrorPageRoutingModule,
        SithecTranslateModule
    ],
    exports: [
        ForbiddenComponent,
        NotFoundComponent
    ]
})

export class ErrorPageModule {}