import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationGuardService } from "projects/e-commerce-common/src/lib/services/authentication-guard.service";

@Injectable({
  providedIn: 'root'
})
export class AdminAuthenticationGuardService extends AuthenticationGuardService {

  /* override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      return true;
  } */
}
