import { Injectable } from "@angular/core";
import { IPurchaseItem } from "@common/lib/models/purchase.model";
import { BehaviorSubject } from "rxjs";

export enum BandType {
  Init = 0,
  Users = 1,
  Quantity = 2,
  Unique = 3
}

@Injectable({
  providedIn: "root",
})
export class ShoppingCarServiceService {
  private shoppingCarBehaviorSubject: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
    private index!:number;
    
  constructor() {
    this.getItemsLocalStorage();
  }

  emptyLocalStorage(){
    localStorage.clear();
  }

  setItemLocalStorage(configurationCard: any) {
    let item = localStorage.getItem("pd-cart");
    let indexLocalStorage = localStorage.getItem("index");
    if(indexLocalStorage==null){
      localStorage.setItem("index", "0");
    }else{
      localStorage.removeItem("index");
      this.index = parseInt(indexLocalStorage);
      this.index++;
      localStorage.setItem("index", this.index+"");
    }
    configurationCard.identificador= this.index;
    
    this.checkHowToSumValue(item ? JSON.parse(item) : [], configurationCard, 0);
  }

  checkHowToSumValue(
    localStorageItems: any[],
    itemToAdd: any,
    bandType: BandType
  ) {
    let auxValue = localStorageItems.find((res) => res.identificador == itemToAdd.identificador);



    switch (bandType) {
      case BandType.Init:
        itemToAdd.users = 1;
        itemToAdd.quantity = itemToAdd.quantity? itemToAdd.quantity:1;
        localStorageItems.push(itemToAdd);
        localStorage.removeItem("pd-cart");
        break;
      case BandType.Users:
        if (auxValue) {
          auxValue.usersQuantity++;
        } else { 
          itemToAdd.users = 1;
          localStorageItems.push(itemToAdd);
          localStorage.removeItem("pd-cart");
        }
        break;
      case BandType.Quantity:
        if (auxValue) {
          auxValue.quantity++;
        } else {
          itemToAdd.quantity = itemToAdd.quantity? itemToAdd.quantity:1;
          localStorageItems.push(itemToAdd);
          localStorage.removeItem("pd-cart");
        }
        break;
      case BandType.Unique:
        if (auxValue) {
          
        } else {
          itemToAdd.users = 1;
          localStorageItems.push(itemToAdd);
          localStorage.removeItem("pd-cart");
        }
        break;  
    }

    localStorage.setItem("pd-cart", JSON.stringify(localStorageItems));
    this.shoppingCarBehaviorSubject.next(localStorageItems);
  }

  changeCost(
    localStorageItems: any[],
    itemToAdd: any
  ) {
    let auxValue = localStorageItems.find((res) => res.identificador == itemToAdd.identificador);

    if (auxValue) {
      if(auxValue.isMono){
        auxValue.isMono = false;
        auxValue.isMulti = true;
        auxValue.selectedCost = auxValue.costMultiRFC;
        auxValue.tipoLicencia = 1;
      } else{
        auxValue.isMono = true;
        auxValue.isMulti = false;        
        auxValue.selectedCost = auxValue.costMonoRFC;
        auxValue.tipoLicencia = 0;
      }
    } 

    localStorage.setItem("pd-cart", JSON.stringify(localStorageItems));
    this.shoppingCarBehaviorSubject.next(localStorageItems);
  }

  substractQuantity(
    localStorageItems: any[],
    itemToAdd: any,
    bandType: BandType
  ) {
    let auxValue = localStorageItems.find((res) => res.identificador == itemToAdd.identificador);

    switch (bandType) {
      case BandType.Users:
        if (auxValue) {
          if (auxValue.usersQuantity >= 2) {
            auxValue.usersQuantity--;
          }
        }
        break;
      case BandType.Quantity:
        if (auxValue) {
          if (auxValue.quantity >= 2) {
            auxValue.quantity--;
          }
        }
        break;
    }

    localStorage.setItem("pd-cart", JSON.stringify(localStorageItems));
    this.shoppingCarBehaviorSubject.next(localStorageItems);
  }

  getItemsLocalStorage() {
    const items = localStorage.getItem("pd-cart");
    if (!items) {
      this.shoppingCarBehaviorSubject.next([]);
    } else {
      this.shoppingCarBehaviorSubject.next(JSON.parse(items as any));
    }
  }

  updateItemsLocalStorage(cartObj: any[]) {
    if (cartObj.length > 0) {
      localStorage.removeItem("pd-cart");
      localStorage.setItem("pd-cart", JSON.stringify(cartObj));
    } else {
      localStorage.removeItem("pd-cart");
    }

    this.getItemsLocalStorage();
  }

  resetCart() {
    localStorage.setItem("index", "0");
    localStorage.removeItem("pd-cart");
    this.shoppingCarBehaviorSubject.next([]);
  }

  getCarAsObservable() {
    return this.shoppingCarBehaviorSubject.asObservable();
  }

  getCartData() {
    return this.shoppingCarBehaviorSubject.getValue();
  }

  getPurchaseItems(): IPurchaseItem[] {
    const cartItems = this.shoppingCarBehaviorSubject.getValue();
    console.log("cartItems",cartItems)
    return cartItems.map((item) => {
      let licencia:string = "";
    switch(item.tipoLicencia){
      case 1:
        licencia = "Nueva Licencia"
        break;
      case 2:
        licencia = "Renovación de licencia"
        break;
      case 3:
        licencia = "Cambio de Licencia"
        break;
    }
      return {
        productId: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: item.selectedCost,
        users: item.usersQuantity,
        isMono: item.isMono,
        usersCost: item.isMono ? item.costMonoRFCUA : item.costMultiRFCUA,
        purchaseType: licencia,
        isInCombo: item.isInCombo,
        licencia: item.licencia,
        License: item.License,
        metadata: item.email?item.email:(item.imei?item.imei:null)
      };
    });
  }
}
