import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IErrorDefinition } from '@common/lib/models/error-definition.model';
import { IUser } from '@common/lib/models/purchase.model';
import { IUserAdmin } from '@common/lib/models/user.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.less']
})
export class UsuariosComponent implements OnInit {

  currentIndex: number = 1;
  totalItems: number = 10;
  sizePage: number = 10;
  deleteId:string = "";
  search = new FormControl('');
  formSubscription!:Subscription

  Usuarios: IUser[]= [];
  showConfirmDelete:boolean = false;
  showChangePassword:boolean = false;
  showSuccessPassword:boolean = false;
  usersData:IUser[] = [];
  generatedPassword: string = ""
  enablePasswordButton:boolean = false;
  copyText:string = "";
  userData = {
    id: '', 
    name: '', 
    lastName: '',
    username: '',
    rfc: '',
    phone: '', 
    razon: '',
    certificado: false,
    calle: '', 
    numero_exterior: '',
    numero_interior: '', 
    estado: '', 
    regimen: '', 
    cp: '', 
    colonia: '', 
    municipio: ''
  };
  userAdminData:IUserAdmin [] = []
  columns = [
    {
      title: 'Nombre', 
      fieldName: 'name',
      type: 'text',
      tagClass: 'admin-title'
    },
    {
      title: 'Correo Electrónico', 
      fieldName: 'username',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Rol', 
      fieldName: 'admin',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Acciones',
      fieldName: 'detalles',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/edit.svg',
          customClass: 'icon',
          actionId: 1,
          type: 'image',
        },
        {
          text: 'assets/images/delete.svg',
          customClass: 'icon',
          actionId: 2,
          type: 'image',
        },
        {
          text: 'assets/images/cambiar_password.svg',
          customClass: 'icon',
          actionId: 3,
          type: 'image',
        }
      ]
    },
  ]
  
  myForm!: FormGroup;
  constructor(
    private router: Router,
    private apiService:AdminApiServiceService,
    private toastrService:ToastrMessageService, 
    private toastr: ToastrService,
    private formBuilder:FormBuilder,
  ) {

   }

  ngOnInit(): void {
    this.copyText = "Copiar"
    this.apiService.getUsers().subscribe(
      (data) => {
        this.Usuarios = data;
      },
      (error) => {
        console.error('Error al cargar los usuarios:', error);
      }
    );
    this.getUsers()

    this.myForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      checkbox1: [true],
      checkbox2: [true],
      checkbox3: [true],
      contrasena: ['']
    }, { validator: this.passwordValidatorIfCheckbox1Unchecked.bind(this) });
  }

  ngAfterViewInit(){
    this.formSubscription=this.search.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.currentIndex = 1;
      this.getUsers()
    })
  } 

  passwordValidatorIfCheckbox1Unchecked(control: AbstractControl): ValidationErrors | null {
    const checkbox1 = control.get('checkbox1')?.value;

    if (!checkbox1) {
      const contrasenaControl = control.get('contrasena');
      const contrasena = contrasenaControl?.value;

      if (!contrasena) {
        return { requiredPassword: true }; // Otra validación si se requiere
      }

      if (!/^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(contrasena)) {
        return { invalidPasswordFormat: true }; // Otra validación si se requiere
      }

      if (contrasena.length < 8 || contrasena.length > 26) {
        return { invalidPasswordLength: true }; // Otra validación si se requiere
      }
    }

    return null;
  }
  async tableButton(event:any){
    this.userData=event.object
    var datos = JSON.stringify(this.userData);
    switch(event.btn.actionId){
      case 1:
        sessionStorage.setItem("editUser", datos)
        this.router.navigate(['/main-page/usuarios/editar/'+this.userData.id]);
        break;
      case 2:
        this.showConfirmDelete = true;
        this.deleteId = this.userData.id
        break;
      case 3:
        this.showChangePassword = true;
        break;
    }
  }

  getUsers(){
    this.apiService.searchAllUserAdmin(this.search.value).subscribe(
      (data) => {
        this.userAdminData = data.results;
        this.userAdminData.forEach(usr =>{
          usr.admin = this.mapAdminValue(usr.admin)
        })
      },
      (error) => {
        console.error('Error al cargar los usuarios:', error);
      }
    );
  }

  closeModalDelete(){
    this.showConfirmDelete = false;
    this.deleteId = "";
  }
  confirmDelete(){
    this.apiService
      .deleteUserAdmin(this.deleteId)
      .subscribe(
          (users: any) => {
            if(users){
              this.getUsers();
              this.showConfirmDelete = false;
              this.deleteId = "";
              this.toastr.success('Usuario eliminado','Acción exitosa');
            }
              // this.productsData=products
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.success(message,'Error');
            // this.toastrService.showError(message)
          }
        );
  }
  changeCurrentIndex(index: number){
    this.currentIndex = index;
    this.getUsers();
  }


  submitForm(): void {
    // Obtener los valores del formulario
    const formValues = this.myForm.value;
    var sendInfo
    // Crear el objeto JSON

      sendInfo = {
        password: formValues.contrasena,
        generatePassword: formValues.checkbox1,
        needsPasswordReset: formValues.checkbox2,
        sendEmail: formValues.checkbox3
      };
    
    console.log(sendInfo);
      this.apiService.changePasswordAdmin(this.userData.id,sendInfo).subscribe(
      (response: any) => {
        if(response){
          // this.toastrService.showSuccess("Producto guardado")
          this.generatedPassword = response.user.password
          this.toastr.success('Contraseña Actualizada','Acción exitosa');
          this.router.navigate(['/main-page/usuarios']);
        }
        console.log("response",response)
      },
      ({ message }: IErrorDefinition) => {
        this.toastr.error(message,'Error');
      }
    );
    

  }
  mapAdminValue(admin: string): string {
    return admin ? 'Admin' : 'Normal';
  }
  verifyPassword(){
    const formValues = this.myForm.value;
    if(formValues.checkbox3){
      this.showSuccessPassword=false;
      this.showChangePassword=false;
    }
    else{
      if(formValues.checkbox1)
      this.showSuccessPassword=true;
      this.showChangePassword=false;
    }
  }
  copiarPortapapeles(){
    const tempTextArea: HTMLTextAreaElement = document.createElement("textarea");
    tempTextArea.value = this.generatedPassword;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    this.enablePasswordButton = true;
    this.copyText = "Copiado!";
  }
}
