<div class="recuadro">
    <div id="nuevo" class="opciones" [formGroup]="productForm">
        <p class="titulo">Licencia Nueva</p>
        <p class="nombre-producto">{{nombreProducto}}</p>
        <p *ngIf="mostrarLicencias" class="subtitulo">Tipo de Licencia:</p>
        <div *ngIf="mostrarLicencias" class="selectores-rfc">
            <div class="selector-rfc" *ngIf="producto?.productFeatures?.annualLicenseMultiRFC? true: false">
                <input type="radio" value="1" name="licencia" formControlName="licencia" (change)="cambiarSeleccion($event)">
                <label>Multi-empresa</label>
                <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Con esta licencia podrás hacer timbrado de hasta 999 empresas diferentes.')">
            </div>
            <div class="selector-rfc" *ngIf="producto?.productFeatures?.annualLicense1RFC? true: false">
                <input type="radio" value="2" name="licencia" formControlName="licencia" (change)="cambiarSeleccion($event)">
                <label>Mono-empresa</label>
                <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Con esta opción podrás hacer el timbrado de un sólo RFC')">
            </div>
        </div>
        <div class="informacion-general">
            <div *ngIf="this.producto?.productFeatures?.annualLicenseMultiRFC && this.producto?.productFeatures?.userAdditionalRFC ||
            this.producto?.productFeatures?.annualLicense1RFC && this.producto?.productFeatures?.userAdditional1RFC">
                <p class="subtitulo">Usuarios</p>
                <p class="texto">Usuario adicional en red</p>
                <div class="acomodo">
                    <p class="texto">Número de Usuarios:</p>
                    <div class="container-quantity">
                        <span class="next" (click)="addQuantity()"></span>
                        <span class="prev" (click)="removeQuantity()"></span>
                        <div id="box">
                            <span>{{usuarios}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separador"></div>
            <p class="subtitulo">Precio</p>
            <div class="selectores-precio" *ngIf="mostrarCombo">
                <div class="selector-precio">
                    <input type="radio" value="1" name="compra" formControlName="compra" (change)="cambiarCombo($event)">
                    <label>Quiero comprar clásica</label>
                    <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Compra clásica: Se refiere a la compra individual de cualquier sistema o sistemas ligados a distintos RFC')">
                </div>
                <div class="selector-precio" *ngIf="producto?.comboEnable">
                    <input type="radio" value="2" name="compra" formControlName="compra" (change)="cambiarCombo($event)">
                    <label>Quiero comprar en combo</label>
                    <img class="info-icon" src="./../../../../../../../assets/images/info_001.svg" (click)="mostrarInfo('Compra en combo: Al hacer tu compra de 2 o más licencias bajo una misma razón social obtienes 15% de descuento')">
                </div>
            </div>
            <div class="acomodo margenes">
                <p class="texto espaciado ajuste">Total</p>
                <p class="subtitulo ajuste">{{total | currency}}</p>
            </div>
            <app-button
                    class="full-width hover-button ajuste add-car"
                    [label]="'CART.ADD' | translate"
                    type="submit"
                    (onClick)="addCard()"
            >
            </app-button>
        </div>
    </div>
</div>
