<nav class="navbar fixed-top navbar-expand navbar-dark scrolling-navbar">
  <div class="acomodo">
    <div *ngIf="!admin; else adminlogo">
      <a routerLink="/landing">
        <img
          class="logo d-none d-lg-block"
          src="assets/images/logoSithecBlanco.png"  
        />
        <img
          class="logo d-lg-none"
          src="assets/images/siboth navbar.svg"  
        />
      </a>
    </div>
    
    <ng-template #adminlogo>
      <a>
        <img
          class="logo  d-none d-lg-block"
          src="assets/images/logoSithecBlanco.png" 
        />
        <img
          class="logo d-lg-none"
          src="assets/images/siboth navbar.svg"  
        />
      </a>
    </ng-template>
    <div class="nav-item search" *ngIf="!admin">
      <div class="img">
        <img src="assets/images/search.svg" alt="">
      </div>
      <app-autocomplete [options]="optionsProducts" placeholder="Buscar Productos" (optionSelected)="onOptionSelected($event)"></app-autocomplete>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <ng-container *ngFor="let item of items">
          <li
            class="nav-item"
            *ngIf="item.items == undefined || item.items.length <= 0"
          >
            <a
              href="javascript:void(0)"
              class="nav-link"
              (click)="routerNavbar(item?.url)"
              *ngIf="item.label"
            >
              {{ item.label | translate}}
            </a>
          </li>
          <li class="nav-item dropdown" *ngIf="item.items">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              *ngIf="item.label"
            >
              {{ item.label | translate }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li *ngFor="let submenu of item.items">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  (click)="routerNavbar(submenu?.url)"
                  *ngIf="submenu.label"
                  >{{ submenu.label | translate }}</a
                >
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav user-info">
        <!-- Iconos navbar -->
        <li id="kart" class="nav-item d-flex align-items-center" *ngIf="!admin">
          <div  class="icon-badge-container" #cartIcon>
            <img src="../../../../../../assets/images/card.svg" alt="" (click)="showSideCart()"  id="kart">
            <div class="icon-badge">{{cartCount}}</div>
          </div>
        </li>
        <li class="nav-item "  *ngIf="user && user.id"> 
        <!-- <li class="nav-item">  -->   
          <a class="nav-link salir seleccion-icon">
            <!-- <img (click)="abrirSeleccion()" src="../../../../../../assets/images/burger.svg"> -->
            <div class="img-profile" (click)="abrirSeleccion()">
              {{getNickName}}
            </div>
          </a>
        </li><li class="nav-item salir-ajuste" *ngIf="!(user && user.id)">  
          <a href="javascript:void(0)" class="nav-link salir" routerLink="account/authentication">
            <img class="ajuste-icon-inicio" src="../../../../../../assets/images/iniciar_sesion_blue.svg" (click)="iniciar()">
          </a>
        </li>
        <!-- <li class="nav-item salir-ajuste display-elements" *ngIf="admin">  
          <p *ngIf="user">{{adminName}}</p>
          <a href="javascript:void(0)" class="nav-link salir" routerLink="account/authentication">
            <img class="ajuste-icon-inicio" src="../../../../../../assets/images/iniciar_sesion_blue.svg" (click)="salir()">
          </a>
        </li>  -->
      </ul>
    </div>
  </div>
</nav>
<!-- <lib-nav-bar-mobile class="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar d-block d-lg-none" [items]="items" [admin]="admin" (showSideCart)="showSideCart()" [badgeItems]="lengthItems"></lib-nav-bar-mobile> -->

<div class="seleccion-vista" [ngClass]="{'seleccion-invisible': !boolVisibleSelection}">

  <div class="seccion-seleccion  seccion-usr" (click)="goTo('informacion')" [hidden]="admin">
    <p class="getFullName">{{ getFullName }}</p>
    <p class="getMail">{{ getMail }}</p>
  </div>  
  <div class="seccion-seleccion seleccionable" (click)="goTo('compras')"  [hidden]="admin">
    <p class="opcion-seleccion">Mis Compras</p>
  </div>
  <div class="seccion-seleccion seleccionable" (click)="goTo('certificados')"  [hidden]="admin">
    <p class="opcion-seleccion">Mis Certificados</p> 
  </div>
  <a href="javascript:void(0)" routerLink="account/authentication">
    <div class="seccion-seleccion seleccionable" (click)="salir()">
      <p class="opcion-seleccion">Cerrar Sesión</p> 
    </div>
  </a>
</div>

<div class="carrito-vista"  [ngClass]="{'seleccion-invisible': !boolVisibleSideBar}" >
  <div class="side-cart">
    <div class="row">
      <div class="col-12" style="text-align: right;" id="shopping-cart"> <button class=" close-btn btn" (click)="boolVisibleSideBar=false">X</button></div>
    </div>
    <div class="row">
      <div class="col-12"> <h3 class="barraCarrito">{{"CART.SIDE_TITLE" | translate}}</h3></div>
    </div>
    <div *ngIf="!emptyComboBool" class="row row-product">
      <div class="col-4">
        <img src="assets/images/COMPAQi COMBO nuevo icono.svg" alt="product" class="product-cart-img">
      </div>
      <div class="col-6 product-cart-info">
        <p><span>Artículos en combo:</span> </p>
      </div>
      <div class="col-2 product-cart-info">
        <p>{{combo[0].length}}</p>
      </div>
      <hr class="divisorCarrito" >
    </div>
    <div *ngFor="let item of products[0]; let i = index">
      <div class="row row-product">
        <div class="col-4">
          <img src="{{item.headerImage}}" alt="product" class="product-cart-img">
        </div>
        <div class="col-6 product-cart-info">
          <p><span>{{ item.name }}</span> </p>
        </div>
        <div class="col-2 product-cart-info">
          <p> {{ item.quantity }}</p>
        </div>
      </div>
      <hr class="divisorCarrito" *ngIf="i < productToBuild.length - 1">
    </div>

    <div class="container">
      <app-button  (onClick)="goToCart()" class="full-width btn-success" [label]="'CART.SIDE_BUTTON' | translate"></app-button>
    </div>
  </div>
</div>
