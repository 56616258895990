import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICardInformationConfiguration } from '@common/lib/models/product.model';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.less']
})
export class AutocompleteComponent {

  showDropdown = false;
  @Input() options: ICardInformationConfiguration[] = [];
  @Input() placeholder: string = "";
  @Output() optionSelected = new EventEmitter<ICardInformationConfiguration>();

  constructor(
    private router: Router
  ){

  }

  filteredOptions: ICardInformationConfiguration[] = [];
  userInput = '';

  filterededOptions() {
    this.filteredOptions = this.options.filter(option =>
      option.name?.toLowerCase().includes(this.userInput.toLowerCase()) ||
      option.reference?.toLowerCase().includes(this.userInput.toLowerCase()) 
    );
  }
  removeAccents(text: string): string {
    const accentMap: { [key: string]: string } = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
    };
  
    return text.replace(/[^\u0000-\u007E]/g, char => accentMap[char] || char);
  }
  
  filterOptions() {
    const normalizedInput =this.removeAccents(this.userInput.toLowerCase());
    this.filteredOptions = this.options.filter(option =>
      (option.name && this.removeAccents(option.name.toLowerCase()).includes(normalizedInput)) ||
      (option.reference && this.removeAccents(option.reference.toLowerCase()).includes(normalizedInput))
 
    );
  }
  

  selectOption(option: ICardInformationConfiguration) {
    this.userInput = option.name!;
    this.optionSelected.emit(option);
    this.filteredOptions = [];
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if(option.productType !=="CONTPAQi" && option.productType !== "CONTPAQ"){
      if(option.productType === "TS_PLUS" || option.productType === "ANTIVIRUS" || option.productType === "SERVICIOS"){
      this.router.navigate(['/product/detail/'+option.id]);
      }
      else{
        var srchprod = JSON.stringify(option)
        if(option.headerImage) sessionStorage.setItem("buscadorimg",option.headerImage)
        sessionStorage.setItem("busquedaProducto",srchprod)
        this.router.navigate(['/product/'+option.productType]);
      }
      
    }
    else{
      if(option.id)
      sessionStorage.setItem('productID', option.id)
      this.router.navigate(['/products/information/nuevo/'+option.id]);
    }
    this.userInput = ""
  }

  showOptions() {
    this.showDropdown = true;
  }

  hideOptions() {
    setTimeout(() => {
      this.showDropdown = false;
      this.userInput = ""
    }, 500);
  }
}
