import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationGuardService } from 'projects/e-commerce-common/src/lib/services/authentication-guard.service';
import { NgModule } from '@angular/core';
import { ControlsModule } from '@common/lib/components/controls/controls.module';
import { SithecTranslateModule } from '@common/lib/components/translate/sithec-translate.module';
import { VentasComponent } from './components/ventas/ventas.component';
import { ECommerceSithecCommonModule } from "@common/lib/e-commerce-common.module";
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { CargarArchivosComponent } from './components/cargar-archivos/cargar-archivos.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { FilterSalePipe } from './pipes/filter-sale.pipe';
import { FilterClientPipe } from './pipes/filter-client.pipe';
import { TableComponent } from './components/table/table.component';
import { FormComponent } from './components/form/form.component';
import { ProductosComponent } from './components/productos/productos.component';
import { FilterTablePipe } from './pipes/filter-table.pipe';
import { CreateProductsComponent } from './components/create-products/create-products.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CreateBannerComponent } from './components/create-banner/create-banner.component';
import { BannersComponent } from './components/banners/banners.component';
import { CreateUserComponent } from './components/create-user/create-user.component';

@NgModule({ 
  declarations: [
    AppComponent,
    VentasComponent, 
    MenuAdminComponent, 
    MainPageComponent, 
    CargarArchivosComponent, 
    ClientesComponent, 
    UsuariosComponent, 
    FilterSalePipe, 
    FilterClientPipe, 
    TableComponent, 
    FormComponent, 
    ProductosComponent, 
    FilterTablePipe, 
    CreateProductsComponent,
    PaginatorComponent,
    CreateBannerComponent,
    BannersComponent,
    CreateUserComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SithecTranslateModule,
    ECommerceSithecCommonModule,
    ImageCropperModule,
    NgxMaskModule.forRoot({
      showMaskTyped : false,
      // clearIfNotMatch : true
    }),
    ToastrModule.forRoot()
  ],
  providers: [
    AuthenticationGuardService,
    { provide: 'recaptchaKey', useValue: environment.common.recaptcha.siteKey },
    { provide: 'recaptchaUrl', useValue: environment.common.recaptcha.apiUrl },
    { provide: 'apiUrl', useValue: environment.common.apiUrl },
    { provide: 'apiPath', useValue: environment.apiPath },
    { provide: 'sourceRequestor', useValue: environment.source },
    { provide: "dataDogApplication", useValue: environment.common.dataDogApplication },
    { provide: 'dataDogClient', useValue: environment.common.dataDogClient },
    { provide: 'datadogEnv', useValue: environment.common.datadogEnv },
    { provide: 'datadogService', useValue: environment.datadogService },
    { provide: 'datadogRecording', useValue: environment.datadogRecording }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
