import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ICardInformationConfiguration } from "@common/lib/models/product.model";
import { ShoppingCarServiceService } from "@web/app/services/shopping-car-service.service";
import { ToastrMessageService } from "@common/lib/services/message.service";
import { ProductInformationService } from "@common/lib/services/product-information.service";

@Component({
  selector: "app-card-information",
  templateUrl: "./card-information.component.html",
  styleUrls: ["./card-information.component.less"],
})
export class CardInformationComponent {
  @Input() configurationCard!: ICardInformationConfiguration;
  @Input() seleccion!: number;
  
  @Output() clickFooterButton: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private shoppingCarService: ShoppingCarServiceService,
    private router: Router,
    private message: ToastrMessageService,
    private productInformation: ProductInformationService
  ) {}

  get backgroundColor() {
    let primaryGradientFrom: string = this.configurationCard.primaryColorFrom
        ? this.configurationCard.primaryColorFrom
        : "#11234B",
      primaryGradientTo: string = this.configurationCard.primaryColorTo
        ? this.configurationCard.primaryColorTo
        : primaryGradientFrom;
    return `linear-gradient(to bottom, ${primaryGradientFrom}, ${primaryGradientTo})`;
  }

  public addCard() {
    console.log(this.configurationCard)
    this.productInformation.setProducto(this.configurationCard);
    this.productInformation.setSeleccion(this.seleccion);
    this.router.navigate(['/products/information/opciones']);
    /* this.message.showInfo('Escogiste el producto: '+this.seleccion); */
  }

  goToCONTPAQi() {
    this.router.navigate(['https://www.contpaqi.com/comercial']);
  }
}
