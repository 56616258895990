import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataDogService } from '@common/lib/services/datadog.service';

@Component({
  selector: 'product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.less']
})
export class ProductInformationComponent implements OnInit {

  activeIndexSelected!: number;
  
  seleccion !: number;
  tipoSeleccionado: string = "";
  nombrePadre: string = "";
  idPadre: string ="";
  actualizarSeleccion(tipo: string) {
    this.tipoSeleccionado = tipo;
  }
  getNombre(nombre:string){
    this.nombrePadre = nombre;
  }
  getIdProducto(id:string){
    this.idPadre = id;
  }
  constructor(
    private dd: DataDogService,
    private router: Router
  ) 
    { 
      
    }

  ngOnInit(): void {
    
  }
  goBack(): void {
    this.router.navigate(['/product/CONTPAQi']);
  }
  ngAfterViewInit(){
    this.dd.startSessionRecording();
  }

  ngOnDestroy(){
    this.dd.stopSessionRecording();
  }

}
