import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '@common/lib/models/purchase.model';

@Pipe({
  name: 'filterClient'
})
export class FilterClientPipe implements PipeTransform {

  transform(clients: IUser[], field:string): IUser[] {
    if (!clients) return[]

    if(field==""){
      return clients
    }else{
      field = field.toLocaleLowerCase();
 
      var name=""
      var lastname=""
      var razon=""
      var fullname=""
      return clients.filter( client=>{
        name = client.name.toLocaleLowerCase()
        lastname = client.lastName.toLocaleLowerCase()
        if(client.razon){
          razon = client.razon.toLocaleLowerCase()
        }
        fullname = name + " " + lastname

        if(name.includes(field)){
            return true
        }
        
        if(lastname.includes(field)){
          return true
        }

        if(fullname.includes(field)){
          return true
        }

        if(client.razon){
          if(razon.includes(field))
          return true
        }
        return false
      });
    }
  }

}
