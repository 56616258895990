import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericSearchService {

  private search$: Subject<any> = new Subject<any>();

  constructor() { }

  setSearchValue(value: any) {
    this.search$.next(value);
  }

  getSearchValue$() {
    return this.search$.asObservable();
  }
}
