import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IBanner } from '@common/lib/models/banner.model';
import { IErrorDefinition } from '@common/lib/models/error-definition.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { SpinnerService } from '@common/lib/services/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.less']
})
export class BannersComponent implements OnInit {

  //Paginator
  currentIndex: number = 1;
  totalItems: number = 10;
  sizePage: number = 10;
  search = new FormControl('');
  formSubscription!:Subscription

  bannersData:IBanner[]=[];
  bannerData!:IBanner;
  showConfirmDelete = false;

  columns = [
    {
      title: 'Nombre', 
      fieldName: 'name',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'Fecha inicial', 
      fieldName: 'initialDate',
      type: 'date',
      tagClass: ''
    },
    {
      title: 'Fecha final',
      fieldName: 'expirationDate',
      type: 'date',
      tagClass: ''
    },
    {
      title: 'Acciones',
      fieldName: 'detalles',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/details.svg',
          customClass: 'icon',
          actionId: 1,
          type: 'image',
        },
        {
          text: 'assets/images/edit.svg',
          customClass: 'icon',
          actionId: 2,
          type: 'image',
        },
        {
          text: 'assets/images/delete.svg',
          customClass: 'icon',
          actionId: 3,
          type: 'image',
        },
        {
          text: 'assets/images/Activar-Banner.svg',
          customClass: 'icon',
          actionId: 4,
          type: 'image',
        },
        {
          text: 'assets/images/pausar-Banner.svg',
          customClass: 'icon',
          actionId: 5,
          type: 'image',
        }
      ]
    },
  ]

  constructor(
    private router: Router,
    private apiService:AdminApiServiceService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService,
    private searchService:GenericSearchService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show()
    this.getBanners();
  }
  
  ngAfterViewInit(){
    this.formSubscription=this.search.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.currentIndex = 1;
      this.getBanners()
    })
  } 
  ngOnDestroy(): void {
    if(this.formSubscription){
      this.formSubscription.unsubscribe()
    }
    
  }

  async tableButton(event:any){
    this.bannerData=event.object
    switch(event.btn.actionId){
      case 1:
        this.router.navigate(['/main-page/banners/editar/'+this.bannerData.id]);
        break;
      case 2:
        this.router.navigate(['/main-page/banners/editar/'+this.bannerData.id]);
        break;
      case 3:
        this.showConfirmDelete = true;
        break;
      case 4:
        this.apiService.pausedBanner(false,this.bannerData.id).subscribe(
          (response: any) => {
            if(response.id){
              this.toastr.success('Se ha activado el banner','Banner');
              this.getBanners();
            }
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.error(message,'Error');
          }
        );
        console.log(this.bannerData)
        break;
      case 5:
        this.apiService.pausedBanner(true,this.bannerData.id).subscribe(
          (response: any) => {
            if(response.id){
              this.toastr.success('Se ha pausado el banner','Banner');
              this.getBanners();
              
            }
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.error(message,'Error');
          }
        );
        break; 
    }
  }

  getBanners(){
    this.apiService
      .getBanners(this.sizePage,this.currentIndex - 1,this.search.value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (banners: any) => {
            if(banners){
              this.totalItems=banners.totalCount;
              this.bannersData=banners.results;
            }
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        );
  }

  onInputChange(event: any) {
    // this.searchService.setSearchValue(event.target.value);
  }

  closeModalDelete(){
    this.showConfirmDelete = false;
  }

  confirmDelete(){
    this.apiService
      .deleteBanner(this.bannerData.id!)
      .subscribe(
          (banners: any) => {
            if(banners){
              this.getBanners();
              this.showConfirmDelete = false;
              this.toastr.success('Banner eliminado','Acción exitosa');
            }
          },
          ({ message }: IErrorDefinition) => {
            this.toastr.success(message,'Error');
            // this.toastrService.showError(message)
          }
        );
  }

  changeCurrentIndex(index: number){
    this.currentIndex = index;
    this.getBanners();
  }

}
