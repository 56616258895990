import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ISwitch } from '@common/lib/models/switch.model';
import { ToastrMessageService } from '@common/lib/services/message.service';
import { Dimensions, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class FormComponent implements OnInit, OnChanges {

  imageChangedEvent: any = '';
  nameFile: any = '';
  croppedImage: any = '';
  imageProduct?: Blob;

  @Input() fields: any[] = [];
  @Input() saveName: any = "Guardar";
  @Input() goBack: any = "/main-page/productos";
  @Input() titleModal: any = "Logotipo del Producto";
  @Input() data: any;
  @Input() imageURL: any = "";
  @Input() spinnerActive: boolean = false;
  @Input() aspectRatio: any = 3 / 3;
  @Input() maintainAspectRatio: any = false;
  @Output() onSubmitOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadImage: EventEmitter<Blob> = new EventEmitter<Blob>();

  dynamicForm!: FormGroup;
  showElement: any = {};
  mostrarCropper = false;
  loading = true;

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private toastrService: ToastrMessageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.dynamicForm = this.fb.group({});
    this.fields.forEach((field, index) => {
      if (field.mandatory) {
        if (field.label)
          field.label += " *"
        this.dynamicForm.addControl(field.fieldName, this.fb.control(field.value != null ? field.value : "", Validators.required));
      } else {
        this.dynamicForm.addControl(field.fieldName, this.fb.control(field.value != null ? field.value : ""));
      }
    });
    this.dynamicForm.valueChanges.subscribe((value) => {
      // Llamar a la función que deseas cada vez que el formulario se actualice
      // console.log(value)
      this.onChangeOut.emit(value);
    });
  }

  onDateChange($event: any){
    console.log("$event",$event)
  }

  ngOnChanges(changes: SimpleChanges): void {
    const data = changes?.['data']?.currentValue;
    if (data) {
      // Recorrer los atributos del objeto
      for (const atribute in data) {
        try {
            this.dynamicForm.controls[atribute]?.setValue(data[atribute])
        } catch (error) {
          // console.error("error",data[atribute],this.dynamicForm.controls[atribute],error)
          
          this.dynamicForm.controls[atribute]?.setValue('')
        }
      }
    }
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      this.onSubmitOut.emit(this.dynamicForm.value);
    } else {
      let atributosInvalidos: string[] = []
      for (var atributo in this.dynamicForm.controls) {
        if(this.dynamicForm.controls[atributo].invalid){
          atributosInvalidos.push(atributo)
        }
      }
      if(atributosInvalidos.length == 1){
        if(atributosInvalidos[0] == 'price'){
          if(this.dynamicForm.controls['productType']?.value == 'CONTPAQi'){
            this.onSubmitOut.emit(this.dynamicForm.value);
            return;
          }
        }
        if(atributosInvalidos[0] == 'costMultiRFC'){
          if(this.dynamicForm.controls['productType']?.value != 'CONTPAQi'){
            this.onSubmitOut.emit(this.dynamicForm.value);
            return;
          }
        }
      }
      this.toastr.warning('Faltan campos por completar', 'Advertencia');
      Object.values(this.dynamicForm.controls).forEach(control => {
        control.markAsTouched();
      });
      // this.toastrService.showError("Faltan campos por completar");
    }
  }

  change(event: any, fieldName: string) {
    //console.log(event.srcElement.checked)
    this.showElement[fieldName] = event.srcElement.checked
  }


  onSelectImage(imageData: string): void {
    // console.log('Imagen seleccionada:', imageData);
    this.imageURL = imageData;
    this.mostrarCropper = true;
  }

  openFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const file = event.target.files && event.target.files[0];

    if (file) {
      this.readImage(file);
    }
  }

  private readImage(file: File): void {
    this.imageChangedEvent = file;
    this.nameFile = file.name;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.onSelectImage(e.target?.result as string);
    };

    reader.readAsDataURL(file);
  }

  cerrar() {
    this.mostrarCropper = false;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    this.imageProduct = event.blob!;
    // console.log(event);
  }

  imageLoaded($event: any) {
    // console.log('Image loaded', $event);
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.loading = false;
  }

  loadImageFailed() {
    // console.error('Load image failed');
  }

  accept() {
    this.imageURL = this.croppedImage;
    this.uploadImage.emit(this.imageProduct);
    this.cerrar();
  }

  blobToFile(blob: Blob, fileName: string): File {
    // Creamos un array de partes para construir un nuevo Blob
    const parts = [blob];
    // Creamos un nuevo Blob a partir de las partes
    const file = new File(parts, fileName, { type: blob.type });
    return file;
  }
}
