import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.less']
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input() currentIndex: number = 1;
  @Input() totalItems: number = 15;
  @Input() sizePage: number = 10;
  @Output() changeCurrentIndex: EventEmitter<number> = new EventEmitter<number>();

  indexPages: number[] = []
  numberPages = 1;

  constructor(
    ) { 
      this.updateIndexPages();
    }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes",changes,this.currentIndex,this.totalItems,this.sizePage)
    this.updateIndexPages()
  }

  ngOnInit(): void {

  }

  goToBack(){
    if(this.currentIndex > 1)
      this.onChangeCurrentIndex(this.currentIndex-1)
  }

  goToNext(){
    if(this.currentIndex < this.numberPages)
    this.onChangeCurrentIndex(this.currentIndex+1)
  }

  onChangeCurrentIndex(newIndex:number){
    this.changeCurrentIndex.emit(newIndex);
  }

  updateIndexPages(){
    this.numberPages= Math.ceil(this.totalItems / this.sizePage);
    this.indexPages = [];
    if(this.numberPages<5){
      for (let index = 1; index <= this.numberPages; index++) {
        this.indexPages.push(index)
      }
    }else{
      if(this.currentIndex>5)
        for (let index = this.currentIndex-2; index <= this.currentIndex+2; index++) {
          if(this.numberPages>=index)
            this.indexPages.push(index)
          else{
            this.indexPages.unshift(this.numberPages - this.indexPages.length)
          }
        }
      else
        for (let index = 1; index <= (this.numberPages <= 5? this.numberPages:5) ; index++) {
          this.indexPages.push(index)
        }
    }
  }

}
