import { Component, OnInit } from '@angular/core';
import { ICardInformationConfiguration } from '@common/lib/models/product.model';
import { ProductInformationService } from '@common/lib/services/product-information.service';
import { Router } from "@angular/router";
import { ToastrMessageService } from "@common/lib/services/message.service";
import { ISwitch } from '@common/lib/models/switch.model';
import { ShoppingCarServiceService } from '@web/app/services/shopping-car-service.service';
@Component({
  selector: 'lib-product-specifications',
  templateUrl: './product-specifications.component.html',
  styleUrls: ['./product-specifications.component.less']
})
export class ProductSpecificationsComponent implements OnInit {
  producto!:ICardInformationConfiguration;
  precioMulti!:number;
  precioMono!:number;
  totalMulti!:number;
  totalMono!:number;
  usuarioMulti!:number;
  usuarioMono!:number;
  usuariosMulti!:number;
  usuariosMono!:number;
  mostrarMulti!:boolean;
  mostrarInformacionMulti!:boolean;
  mostrarUsuariosMulti!:boolean;
  mostrarMono!:boolean;
  mostrarInformacionMono!:boolean;
  mostrarUsuariosMono!:boolean;
  seleccion!:string;
  seleccionable!:boolean;
  seleccionado!:string;

  action:string="el timbrado"
  constructor(
    private shoppingCarService: ShoppingCarServiceService,
    private productService:ProductInformationService,
    private router:Router,
    private message:ToastrMessageService
    ) {
    this.seleccion="Multiempresa"
    this.usuariosMulti=1;
    this.usuariosMono=1;
    this.producto=this.productService.getProducto();
    this.precioMulti=this.producto.costMultiRFC!;
    this.precioMono=this.producto.costMonoRFC!;
    this.usuarioMulti=this.producto.costMultiRFCUA!;
    this.usuarioMono=this.producto.costMonoRFCUA!;
    this.totalMulti=this.precioMulti;
    this.totalMono=this.precioMono;

    this.seleccionable=true;
    this.seleccionado="1";

    if(this.producto.name=="Contabilidad"){
      this.action="la contabilidad"
    } else if(this.producto.name=="XML en Línea+"){
      this.action="las descargas"
    }

    this.producto.isUnique=false;
    /* debugger; */
    if(this.precioMulti==null){
      this.mostrarMulti=false;
      this.seleccionable=false;

      this.producto.isMulti=false;
      this.producto.isMono=true;
      this.seleccion= "Monoempresa";
    }else{
      this.mostrarMulti=true;
    }
    if(this.precioMono==null){ 
      this.mostrarMono=false;
      this.seleccionable=false;

      this.producto.isMulti=true;
      this.producto.isMono=false;
      this.seleccion= "Multiempresa";
    }else{
      this.mostrarMono=true;
    }
    if(this.usuarioMulti==null){
      this.mostrarUsuariosMulti=false;
      this.producto.isUnique=true;
    }else{
      this.mostrarUsuariosMulti=true;
    }
    if(this.usuarioMono==null){
      this.mostrarUsuariosMono=false;
      this.producto.isUnique=true;
    }else{
      this.mostrarUsuariosMono=true;
    }
    if(this.mostrarMulti && this.mostrarMono){
      this.alternar();
    }
   }

  cambiarSeleccion(valor:any){
    this.seleccionado=valor;
    this.alternar();
  }

  addQuantityMulti(){
    this.usuariosMulti++;
    this.totalMulti+=this.usuarioMulti;
  }
  removeQuantityMulti(){
    if(this.usuariosMulti>1){
      this.usuariosMulti--;
      this.totalMulti-=this.usuarioMulti;
    }
  }
  addQuantityMono(){
    this.usuariosMono++;
    this.totalMono+=this.usuarioMono;
  }
  removeQuantityMono(){
    if(this.usuariosMono>1){
      this.usuariosMono--;
      this.totalMono-=this.usuarioMono;
    }
  }
  public addCard() {
    if(this.seleccion == "Multiempresa"){
      this.producto.tipoLicencia = 1;
      this.producto.usersQuantity = this.usuariosMulti;
      this.producto.selectedCost = this.producto.costMultiRFC;
      if(this.usuarioMulti!=null){
        this.producto.costMultiRFCUA = this.usuarioMulti
      }else{
        this.producto.costMultiRFCUA = 0;
      }
      this.producto.isMulti=true;
      this.producto.isMono=false;
    }else{
      this.producto.tipoLicencia = 1;
      this.producto.usersQuantity = this.usuariosMono;
      this.producto.selectedCost = this.producto.costMonoRFC;
      this.producto.costMonoRFCUA = this.usuarioMono
      if(this.usuarioMono!=null){
        this.producto.costMonoRFCUA = this.usuarioMono
      }else{
        this.producto.costMonoRFCUA = 0;
      }
      this.producto.isMulti=false;
      this.producto.isMono=true;
    }
    this.shoppingCarService.setItemLocalStorage(this.producto);
    this.router.navigate(['/cart']);
    this.message.showInfo('Producto añadido exitosamente');
  }
  ngOnInit(): void {
  }
  cambiar(data:ISwitch){
    this.alternar();
  }
  alternar(){
    if(this.seleccionado=="1"){
      this.mostrarMulti=true;
      this.mostrarMono=false;
      this.producto.isMono=false;
      this.producto.costMultiRFCUA = this.precioMulti;
      this.seleccion="Multiempresa"
      
    }else{
      this.mostrarMulti=false;
      this.mostrarMono=true;
      this.producto.isMono=true;
      this.producto.costMonoRFCUA = this.precioMono;
      this.seleccion="Monoempresa"
    }
  }

}
