import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargar-archivos',
  templateUrl: './cargar-archivos.component.html',
  styleUrls: ['./cargar-archivos.component.less']
})
export class CargarArchivosComponent implements OnInit {

    file : any

    constructor() {}

    ngOnInit(): void {}

    async setFile(event: any) {
        if (!event || !event.target || !event.target.files || event?.target?.files?.length == 0)
            return;

        this.file = event.target.files[0];

        if(!this.file.type.includes('pdf'))
        {
            var noImageData = {
                name : 'Formato Incorrecto',
                message : 'El archivo no es una imagen. Por favor, suba otro con el formato correcto.',
                noCancel : true
            }

            this.file = null;
        }
        else if(this.file.size > 2097152 )
        {
            var noImageData = {
                name : 'Limite excedido',
                message : 'El archivo excedió el limite de 2 MB.',
                noCancel : true
            }

            this.file = null;
        }

    }


}
