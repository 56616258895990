
<div class="all-container">
    <div class="catalog-title">
        <h2>Subir Política de Prevención</h2>
        <div class="f-separator"></div>
    </div>
    <div class="form-container">
        <div class="file-field">
            <div>    
                <h5>*Formato PDF</h5>
                <h5>*Peso máximo: 2 MB</h5>
            </div>
    
            <div class="file-button">
                <input id="file" type="file" name="file" (change)="setFile($event)" #fileInput  accept="application/pdf">
                <button mat-raised-button color="primary" (click)="fileInput.click()">
                    {{(!file) ? 'Examinar...' : 'Archivo: ' + file.name }}
                </button>
            </div>
        </div>
    </div>
</div>
