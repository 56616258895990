import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTable'
})
export class FilterTablePipe implements PipeTransform {

  transform(data: any[], search:any): any[] {
    if (!data) return[];

    if(search==""){
      return data;
    } else {
      search = this.removeAccents(search?.toLocaleLowerCase());

      return data.filter(dataInformation => {
        

        const name = this.removeAccents(dataInformation.name?.toLocaleLowerCase() || "");
        const reference = this.removeAccents(dataInformation.reference?.toLocaleLowerCase() || "");
        const productCategoryName = this.removeAccents(dataInformation.productCategory?.name?.toLocaleLowerCase() || "");
        const productType = this.removeAccents(dataInformation.productType?.toLocaleLowerCase() || "");
        const lastname = this.removeAccents(dataInformation.lastname?.toLocaleLowerCase() || "");
        const razon = this.removeAccents(dataInformation.razon?.toLocaleLowerCase() || "");
        const fullname = this.removeAccents(name + " " + lastname);

        if (
          name.includes(search) ||
          reference.includes(search) ||
          productCategoryName.includes(search) ||
          productType.includes(search) ||
          lastname.includes(search) ||
          fullname.includes(search) ||
          razon.includes(search)
        ) {
          return true;
        }
        
        if (dataInformation?.user) {
          const userName = this.removeAccents((dataInformation.user?.name + " " + dataInformation.user?.lastName).toLocaleLowerCase() || "");
          if (userName.includes(search)) {
            return true;
          }
        }

        return false;
      });
    }
  }

  removeAccents(str: string){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
}
