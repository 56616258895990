import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { catchError, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { IAuthenticationResponse } from "../models/authentication-response.model";
import { ILoginRequest } from "../models/login-request.model";
import { SithecBaseApiService } from "./sithec-base-api.service";
import { UserService } from "./user.service";
import { SessionService } from "./session.service";
import { ISignUpRequest } from "../models/sign-up-request.model";
import { IAccountVerification } from "../models/account-verification.model";
import { IRequestAccountVerification } from "../models/request-account-verification.model";
import { IResetPasswordRequest, IResetPasswordResponse } from "../models/reset-password";
import { IVerifyAccountRequest } from "../models/verify-account-request.model";
import { IUpdatePasswordRequest } from "../models/update-password";
import { IUser } from "../models/user.model";
import { IDeleteInformation } from '@common/lib/models/delete.model';
import { IUpdate } from "../models/update.model";
import { UpdateRequest } from "../classes/update-request";
import { IFileRequest } from "../models/file-request";
import { DataDogService } from "./datadog.service";

@Injectable({
  providedIn: "root",
})
export class SithecCommonApiService extends SithecBaseApiService {
  constructor(
    @Inject("apiUrl") protected apiUrl: string,
    @Inject("sourceRequestor") protected sourceRequestorSource: string,
    protected httpClient: HttpClient,
    protected userServiceSource: UserService,
    protected sessionServiceSource: SessionService,
    private translateServiceSource: TranslateService,
    private routerSource: Router,
    protected dataDogService: DataDogService
  ) {
    super(
      
      sourceRequestorSource,
      userServiceSource,
      sessionServiceSource,
      translateServiceSource,
      routerSource,
      dataDogService
    );
  }

  login(request: ILoginRequest) {
     return this.httpClient
       .post<IAuthenticationResponse>(
        `${this.apiUrl}/authentication/login`,
        request,
        { headers: this.getHeaders() }
      )
      .pipe(
        tap((response) =>{
          console.log(response)
          this.handleAuthenticationResponse(response)
        } ),
        catchError((error) => this.handleError(error))
      );
  } 

  signUp(request: ISignUpRequest): Observable<IAuthenticationResponse> {
    /* console.log(request) */
    return this.httpClient
      .post<IAuthenticationResponse>(
        `${this.apiUrl}/authentication/signup`,
        request,
        { headers: this.getHeaders() }
      )
      .pipe(
        tap((response) => console.log(response)),
        catchError((error) => this.handleError(error))
      );
  }

  signUpSMS(request: ISignUpRequest): Observable<IAuthenticationResponse> {
    /* console.log(request) */
    return this.httpClient
      .post<IAuthenticationResponse>(
        `${this.apiUrl}/authentication/signupsms`,
        request,
        { headers: this.getHeaders() }
      )
      .pipe(
        tap((response) => console.log(response)),
        catchError((error) => this.handleError(error))
      );
  }

  validateValues(email:string, phone:string): Observable<IAuthenticationResponse> {
    /* console.log(request) */
    return this.httpClient
      .post<IAuthenticationResponse>(
        `${this.apiUrl}/authentication/validateemailphone`,
        {
          email:email,
          phone:phone
        },
        { headers: this.getHeaders() }
      )
      .pipe(
        tap((response) => console.log(response)),
        catchError((error) => this.handleError(error))
      );
  }

  verify(userId: string, token:string){
    return this.httpClient
    .post<IAuthenticationResponse>(
      `${this.apiUrl}/authentication/checkingemail`,
      {
        userId:userId,
        token:token
      },
      { headers: this.getHeaders() }
    )
    .pipe(
      tap((response) =>{
        console.log(response)
        this.handleAuthenticationResponse(response)
      } ),
      catchError((error) => this.handleError(error))
    );
  }

  update(request: UpdateRequest): Observable<IUpdate>{
    const formData:any = new FormData();

    formData.append("id", request.user.id);
    formData.append("name", request.user.name);
    formData.append("lastName", request.user.lastName);
    formData.append("username", request.user.username);
    formData.append("phone", request.user.phone!);
    formData.append("calle", request.user.calle!);
    formData.append("colonia", request.user.colonia!);
    formData.append("persona", request.user.persona!);
    formData.append("cp", request.user.cp!);
    formData.append("estado", request.user.estado!);
    formData.append("municipio", request.user.municipio!);
    formData.append("numero_exterior", request.user.numero_exterior!);
    formData.append("numero_interior", request.user.numero_interior!);
    formData.append("razon", request.user.razon!);
    formData.append("regimen", request.user.regimen!);
    formData.append("rfc", request.user.rfc!);
    if(!request.constanciaCarga){
      formData.append("certificado", request.constancia! ? "true":"false");
    }else{
      formData.append("certificado", true);
    }
    formData.append("fileUpload", request.constancia);
    
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }

    return this.httpClient
      .post<IUpdate>(
        `${this.apiUrl}/account/updateuser`,
        formData
      )
      .pipe(
        tap((response) => this.handleUpdateResponse(response)),
        catchError((error) => this.handleError(error))
      );
  }

  verifyAccount(
    request: IVerifyAccountRequest
  ): Observable<IAccountVerification> {
    return this.httpClient
      .post<IAccountVerification>(`${this.apiUrl}/account/verify`, request, {
        headers: this.getHeaders()
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
  
  resendAccountVerification(
    request: IRequestAccountVerification
  ): Observable<IAccountVerification> {
    return this.httpClient
      .post<IAccountVerification>(
        `${this.apiUrl}/account/verifications`,
        request,
        {
          headers: this.getHeaders()
        }
      )
      .pipe(catchError((error) => this.handleError(error)));
  }
  
  resetPassword(
    request: IResetPasswordRequest
  ): Observable<IResetPasswordResponse> {
    return this.httpClient
      .post<IResetPasswordResponse>(
        `${this.apiUrl}/account/resetpassword`,
        request,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  updatePassword(request: IUpdatePasswordRequest): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/account/updatepassword`, request, {
        headers: this.getHeaders()
      })
      .pipe(catchError((error) => this.handleError(error)));
  }  

  
  logout(): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/authentication/logout`, null, {
        headers: this.getHeaders()
      })
      .pipe(
        tap(() => this.handleLogoutResponse()),
        catchError((error) => this.handleError(error))
      );
  }
  deleteFile(fileDelete: IDeleteInformation){
    return this.httpClient.post(`${this.apiUrl}/file/deleteobject`, fileDelete,{
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  getURLFile(fileRequest: IFileRequest):Observable<string>{
    console.log(fileRequest)
    return this.httpClient.post<string>(`${this.apiUrl}/file/getobjecturl`, fileRequest,{
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  downloadURLFile(fileRequest: IFileRequest):Observable<string>{
    console.log(fileRequest)
    return this.httpClient.post<string>(`${this.apiUrl}/file/downloadFile`, fileRequest,{
      headers: this.getHeaders(),
    })
    .pipe(catchError((error) => this.handleError(error)));
  }

  uploadObject(itemData: FormData): Observable<any> {
    return this.httpClient
      .post(`${this.apiUrl}/file/uploadFileImage`, itemData, {
        headers: this.getFormHeaders(true),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }
}
