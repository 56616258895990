import { Component, OnInit } from '@angular/core';
import { IUser } from '@common/lib/models/purchase.model';
import { AdminApiServiceService } from '@admin/app/services/admin-api-service.service';
import { SpinnerService } from "@common/lib/services/spinner.service";
import { ToastrMessageService } from "@common/lib/services/message.service";
import { debounceTime, finalize } from "rxjs/operators";
import { IErrorDefinition } from "@common/lib/models/error-definition.model";
import { min } from 'lodash';
import { IFileRequest } from '@common/lib/models/file-request';
import { SithecCommonApiService } from '@common/lib/services/sithec-common-api.service';
import Swal from 'sweetalert2';
import { GenericSearchService } from '@admin/app/services/generic-search.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.less']
})
export class ClientesComponent implements OnInit {
  currentIndex: number = 1;
  totalItems: number = 10;
  sizePage: number = 10;
  mostrarDetalles: boolean = false;
  clientsData: IUser[] = [];
  clientData!: IUser
  search = new FormControl('');
  formSubscription!:Subscription

  contacto?:string
  empresa?:string
  rfc?:string
  domicilio?:string
  regimen?:string
  telefono?:string
  correo?:string
  clientID:string = ""
  certificado:boolean = false
  
  columns = [
    {
      title: 'Contacto', 
      fieldName: 'name',
      type: 'text'
    },
    {
      title: 'Razón Social',
      fieldName: 'razon',
      type: 'text'
    },
    {
      title: 'Correo',
      fieldName: 'username',
      type: 'text'
    },
    {
      title: 'Teléfono',
      fieldName: 'phone',
      type: 'text'
    },
    {
      title: 'RFC',
      fieldName: 'rfc',
      type: 'text',
      tagClass: ''
    },
    {
      title: 'CSF',
      fieldName: 'certificado',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/csf-turn.svg',
          customClass: 'icon',
          actionId: 1,
          type: 'csf',
        }
      ]
    },
    {
      title: 'Ver detalle',
      fieldName: 'detalles',
      type: 'button',
      ctas: [
        {
          text: 'assets/images/details.svg',
          customClass: 'icon',
          actionId: 2,
          type: 'image',
        }
      ]
    }
  ]


  constructor(
    private apiService:AdminApiServiceService, 
    private spinnerService:SpinnerService, 
    private toastrService:ToastrMessageService, 
    private sithecCommonApiService:SithecCommonApiService,
    private searchService:GenericSearchService
  ) { } 

  ngOnInit(): void {
    this.spinnerService.show()
    this.apiService
      .getUsers()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
          (users: IUser[]) => {
              this.clientsData=users
          },
          ({ message }: IErrorDefinition) => this.toastrService.showError(message)
        ); 
  }
  ngAfterViewInit(){
    this.formSubscription=this.search.valueChanges
    .pipe(
      debounceTime(500) // Esperar 500 milisegundos antes de continuar
    )
    .subscribe((data)=>{
      this.currentIndex = 1;
      this.getUsers()
    })
  } 

  getUsers(){
    this.apiService.getAllUser(this.search.value).subscribe(
      (data) => {
        this.clientsData = data.results;
      },
      (error) => {
        console.error('Error al cargar los usuarios:', error);
      }
    );
  }

  async tableButton(event:any){
    this.clientData=event.object
    switch(event.btn.actionId){
      case 1:
        this.redirigir(this.clientData.certificado, this.clientData.rfc)
        break;
      case 2:
        this.contacto=this.clientData.name+" "+this.clientData.lastName
        this.empresa=this.clientData.razon
        this.rfc=this.clientData.rfc
        this.clientID=this.clientData.id
        this.certificado=this.clientData.certificado
        if(this.clientData.calle && this.clientData.numero_interior)
          this.domicilio=this.clientData.calle + " " + this.clientData.numero_exterior + ", Int " + this.clientData.numero_interior + ", " + this.clientData.municipio +", "+this.clientData.estado+", "+this.clientData.cp
        else if(this.clientData.calle)
          this.domicilio=this.clientData.calle + " " + this.clientData.numero_exterior + ", " + this.clientData.municipio+", "+this.clientData.estado+", "+this.clientData.cp
        else
          this.domicilio=""
        this.regimen=this.clientData.regimen
        this.telefono=this.clientData.phone
        this.correo=this.clientData.username
        this.mostrarDetalles= true;
        break;
    }
  }
  

  /* abrir(clientID:string, certificado:boolean, nombre:string, apellido:string,empresa:string,rfc:string,domicilio:string, numero_interior:string, numero_exterior:string, estado:string,regimen:string,telefono:string,correo:string, clientData:IUser){
    this.contacto=nombre+" "+apellido
    this.empresa=empresa
    this.rfc=rfc
    this.clientID=clientID
    this.certificado=certificado
    this.clientData=clientData
    if(domicilio && numero_interior)
      this.domicilio=domicilio + " " + numero_exterior + ", Int " + numero_interior + ", " + this.clientData.municipio +", "+estado+", "+this.clientData.cp
    else if(domicilio)
      this.domicilio=domicilio + " " + numero_exterior + ", " + this.clientData.municipio+", "+estado+", "+this.clientData.cp
    else
      this.domicilio=""
    this.regimen=regimen
    this.telefono=telefono
    this.correo=correo
    this.mostrarDetalles= true;
  } */

  cerrar(){
    this.mostrarDetalles= false;
  }

  trackClient(index:number, item:IUser){
    return item.id;
  }
  getIcon(certificado:boolean):string{
    return certificado? "../../../../../assets/images/descargar_icon_green.svg" : "../../../../../assets/images/descargar icon-gray.svg";
  }

  async redirigir(certificado:boolean, Id:string){
    if(certificado){
      this.toastrService.showInfo("Tu documento está siendo cargado")
      await this.getURLFile(Id+".pdf", 'constancias')
    }else{
      //this.toastrService.showWarn("Este producto aún no ha sido certificado")
      Swal.fire(
        'Advertencia',
        'No se ha cargado una constancia de situación fiscal',
        'warning'
      )
    }
  }
  async getURLFile(name:string, subdirectory:string){
    const file: IFileRequest= {
      objectKey: name,
      subDirectory: subdirectory,
      duration: 2
    };
    var url=""
    await this.sithecCommonApiService
    .getURLFile(file).subscribe((res) => {
      url=res
      //window.location.href=url
      window.open(url, '_blank');
    });
  }
}
