<form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
  <ng-container *ngFor="let field of fields; let i = index">
    <div class="div-column" *ngIf="!field.hide">
      <label>{{ field.label }}
        <span *ngIf="dynamicForm.get(field.fieldName)?.hasError('required') && dynamicForm.get(field.fieldName)?.touched"
        class="error-message">Este campo es obligatorio.</span>
        </label>
        <ng-container *ngIf="field.type=='text'">
          <input type="text" [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName">
        </ng-container>
        <ng-container *ngIf="field.type=='date'">
          <input type="date" [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName" (input)="onDateChange($event)">
        </ng-container>
        <ng-container *ngIf="field.type=='number'">
          <input type="number" [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName">
        </ng-container>
        <ng-container *ngIf="field.type=='money'">
          <input type="text" mask="separator.2" 
            thousandSeparator=","
            prefix="$ "
            suffix=" MXN"
            [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName">
        </ng-container>
        <ng-container *ngIf="field.type=='percent'">
          <input type="text" mask="percent.2" 
            thousandSeparator=","
            suffix="%"
            [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName">
        </ng-container>
        <ng-container *ngIf="field.type=='switch'">
          <form-switch (change)="change($event,field.fieldName)" [rightLabel]="field.text"></form-switch>
          <input *ngIf="showElement[field.fieldName]" class="margin-top" type="number"
            [placeholder]="field.placeholder!=undefined?field.placeholder:field.label" [formControlName]="field.fieldName">
        </ng-container>
        <ng-container *ngIf="field.type=='textarea'">
          <textarea cols="30" rows="10" [placeholder]="field.placeholder!=undefined?field.placeholder:field.label"
            [formControlName]="field.fieldName"></textarea>
        </ng-container>
        <ng-container *ngIf="field.type=='select'">
          <select [formControlName]="field.fieldName">
            <option value="">* Seleccionar opción</option>
            <option *ngFor="let option of field.options" [value]="option.value">{{option.text}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="field.type=='file'">
          <input type="file" accept="image/*" (change)="onFileSelected($event)" #fileInput style="display: none"
                [formControlName]="field.fieldName">
          <div class="form-input-file" *ngIf="!imageURL">
            <div>
              <img class="icon-file" src="../../../../../assets/images/add-icon.svg" (click)="openFileInput()">
            </div>
            <div class="text">{{field.title}} <br> {{field.text}} <br> {{field.description}}</div>
          </div>
          <div class="form-input-file" *ngIf="imageURL">
            <div style="height: 90%; display: contents;">
              <img class="icon-file-selected" [src]="imageURL" (click)="openFileInput()">
            </div>
          </div>
        </ng-container>  
    </div>
  </ng-container>
  <div class="buttons" *ngIf="!spinnerActive">
    <button class="save-button" type="submit" [ngClass]="{'can-submit':dynamicForm.valid}">{{saveName}}</button>
    <button class="cancel-button" [routerLink]="[goBack]">Cancelar</button>
  </div>
  <div class="spinner" *ngIf="spinnerActive">  
    <div class="spinner-border text-primary" role="status"></div>
  </div>
</form>

<div class="modal fade bd-example-modal-xl" [ngClass]="{'show d-block':mostrarCropper}" id="abrirReporte">
  <div class="modal-dialog modal-xl">
    <div class="modal-content height-modal">
      <div class="modal-header">
        <div class="ajuste-detalle">
          <h5 class="modal-title">{{titleModal}}</h5>
          <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="cerrar()">
        </div>
      </div>
      <div class="row height-modal-area">
        <div class="col-8 height-modal-area">
          <section style="height: 90%">
            <image-cropper *ngIf="imageURL" class="height-modal-area" [imageURL]="imageURL" [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="aspectRatio" format="png"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
            <div *ngIf="loading" class="loader">Loading...</div>
          </section>
        </div>
        <div class="col-4">
          <section>
            <div>
              Visualización
            </div>
            <img style="width: 100%;" [src]="croppedImage" />
          </section>
          <div class="buttons mt-3">
            <button class="accept-button" (click)="accept()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>