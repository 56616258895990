<div class="table-container">
  <table class="table table-striped">
    <thead class="posicion-encabezado">
      <tr>
        <th *ngFor="let column of columns" class="center">{{ column.title }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data | filterTable: search;">
        <td *ngFor="let column of columns"  class="center">
          <ng-container *ngIf="column.type == 'text'">
            <ng-container *ngIf="column.tagClass">
              <span [ngClass]="column.tagClass || ''">
                {{ row[column.fieldName] }}
              </span>
            </ng-container>
            <ng-container *ngIf="!column.tagClass">
              {{ getValue(row,column.fieldName) }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.type == 'paymentMethod'">
            <ng-container *ngIf="column.tagClass">
              <span [ngClass]="column.tagClass || ''">
                {{ debitOrCredit(row[column.fieldName]) }}
              </span>
            </ng-container>
            <ng-container *ngIf="!column.tagClass">
              {{ getValue(row,column.fieldName) }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.type == 'price'">
            <ng-container *ngIf="row['productType']!='CONTPAQi'">
              {{ (row[column.fieldName]?row[column.fieldName]:0) | currency: 'USD' | lowercase }}
            </ng-container>
            <ng-container *ngIf="row['productType']=='CONTPAQi'">
              <span class="link-prices" (click)="openPrices(row)"> Ver detalles </span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.type == 'user'">
            <p [ngClass]="column.tagClass || ''">{{row[column.fieldName].name}} {{row[column.fieldName].lastName}}</p>
          </ng-container>
          <ng-container *ngIf="column.type == 'list'">
            <ng-container *ngFor="let detail of row[column.fieldName]"> 
              <div *ngIf="!detail.isInCombo">
                <p>{{detail.name}} {{detail.isMono ? "Mono-empresa":"Multi-empresa"}}</p>
                <p>{{detail.users}} Usuario [{{detail.purchaseType}}]</p>
              </div>
              <p class="negritas" *ngIf="checkCombo(row[column.fieldName])">COMBO</p>
              <div *ngFor="let detail of row[column.fieldName]">
                  <div *ngIf="detail.isInCombo" class="tab-combo-content">
                      <p>{{detail?.name}} {{detail.isMono ? "Mono-empresa":"Multi-empresa"}}</p>
                      <p>{{detail?.users}} Usuario [{{detail?.purchaseType}}]</p>
                  </div>
              </div>
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="column.type == 'card'">
            <p>Tarjeta: --{{ row[column.fieldName]?.[row[column.fieldName]?.length-2]  }}{{ row[column.fieldName]?.[row[column.fieldName]?.length-1]  }}</p>
          </ng-container>

          <ng-container *ngIf="column.type == 'date'">
            <p>{{ row[column.fieldName]?.split('T')[0] }}</p>
          </ng-container>

          <ng-container *ngIf="column.type == 'tag'">
            <ng-container *ngIf="row[column.fieldName] == false">
              <div class="status">
                <img class="icon" src="assets/images/uncheck.svg" alt="">
              </div>
            </ng-container>
            <ng-container *ngIf="row[column.fieldName] == true">
              <div class="status">
                <img class="icon" src="assets/images/check.svg" alt="">
              </div>
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="column.type == 'button'">
            <div class="actions">
              <ng-container *ngFor="let btn of column.ctas"> 
                  <ng-container *ngIf="isBanner(row);else notBanner">
                    <ng-container *ngIf="btn.actionId !== 4 &&  btn.actionId !== 5">
                      <button (click)="ctaClick(row, btn)" *ngIf="btn.type=='button'" [ngClass]="btn.customClass || ''">{{btn.text}}</button>
                      <div class="status">
                        <img (click)="ctaClick(row, btn)" *ngIf="btn.type=='image'" [ngClass]="btn.customClass || ''" [src]="btn.text">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.paused && btn.actionId === 4 &&  btn.actionId !== 5">
                      <button (click)="ctaClick(row, btn)" *ngIf="btn.type=='button'" [ngClass]="btn.customClass || ''">{{btn.text}}</button>
                      <div class="status">
                        <img (click)="ctaClick(row, btn)" *ngIf="btn.type=='image'" [ngClass]="btn.customClass || ''" [src]="btn.text">
                      </div>
                    </ng-container>
                      <ng-container *ngIf="!row.paused && btn.actionId !== 4 &&  btn.actionId === 5">
                        <button (click)="ctaClick(row, btn)" *ngIf="btn.type=='button'" [ngClass]="btn.customClass || ''">{{btn.text}}</button>
                        <div class="status">
                          <img (click)="ctaClick(row, btn)" *ngIf="btn.type=='image'" [ngClass]="btn.customClass || ''" [src]="btn.text">
                        </div>
                    </ng-container>
                  </ng-container>
                
                <ng-template #notBanner>
                  <button (click)="ctaClick(row, btn)" *ngIf="btn.type=='button'" [ngClass]="btn.customClass || ''">{{btn.text}}</button>
                  <div class="status">
                    <img (click)="ctaClick(row, btn)" *ngIf="btn.type=='image'" [ngClass]="btn.customClass || ''" [src]="btn.text">
                  </div>
                </ng-template>
                <ng-container *ngIf="row[column.fieldName] == true">
                  <div class="status">
                    <img (click)="ctaClick(row, btn)" class="icon" src="assets/images/csf-turn.svg" alt="">
                  </div>
                </ng-container>
                <ng-container *ngIf="row[column.fieldName] == false">
                  <div class="status">
                    <img (click)="ctaClick(row, btn)" class="icon" src="assets/images/csf-off.svg" alt="">
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal fade bd-example-modal-md" [ngClass]="{'show d-block':rowPrices}">
  <div class="modal-dialog modal-md">
    <div class="modal-content height-modal" *ngIf="rowPrices">
      <div class="modal-header">
        <div class="ajuste-detalle">
          <h5 class="modal-title">{{rowPrices.name}}</h5>
          <img src="../../../../../assets/images/out_modal.svg" alt="" (click)="closeModalPrices()">
        </div>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Costo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mono costo RFC</td>
              <td>{{rowPrices.costMonoRFC | currency: 'USD' | lowercase }}</td>
            </tr>
            <tr>
              <td>Mono costo RFCUA</td>
              <td>{{rowPrices.costMonoRFCUA | currency: 'USD' | lowercase }}</td>
            </tr>
            <tr>
              <td>Multi costo RFC</td>
              <td>{{rowPrices.costMultiRFC | currency: 'USD' | lowercase }}</td>
            </tr>
            <tr>
              <td>Multi costo RFCUA</td>
              <td>{{rowPrices.costMultiRFCUA | currency: 'USD' | lowercase }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>