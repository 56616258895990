import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ICardInformationConfiguration, IProductCategory } from "@common/lib/models/product.model";

@Injectable({
  providedIn: 'root'
})
export class ProductInformationService {
  private index!:number;
  private seleccion!:number;
  private producto!: ICardInformationConfiguration;

  PRODUCT = 'custom:last:product'
  SELECTION = 'custom:last:selection'

  constructor() { }

  setIndex(index:number){
    this.index=index;
  }
  getIndex():number{
    return this.index;
  }
  setSeleccion(seleccion:number){
    localStorage.setItem(this.SELECTION, JSON.stringify(this.seleccion)) 
    this.seleccion=seleccion;
  }
  getSeleccion():number{
    return this.seleccion ? this.seleccion : this.getFromLS(this.SELECTION);
  }
  setProducto(producto:ICardInformationConfiguration){
    localStorage.setItem(this.PRODUCT, JSON.stringify(producto))
    this.producto=producto;
  }
  getProducto():ICardInformationConfiguration{
    //return this.producto ? this.producto : this.getFromLS(this.PRODUCT);
    return this.getFromLS(this.PRODUCT);
  }


  getFromLS(key:string){
    const obj = localStorage.getItem(key)
    if(!obj){
      return null;
    }
    return JSON.parse(obj)
  }
}
