import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { ControlsModule } from "./components/controls/controls.module";
import { ErrorPageModule } from "./components/error-page/error-page.module";
import { ProductsModule } from "../../../e-commerce-web/src/app/components/products/products.module";
import { SocialLoginModule } from "angularx-social-login";
import {  httpLoaderFactory, SithecTranslateModule } from "./components/translate/sithec-translate.module";
import { PipesModule } from "./pipes/pipes.module";
import { MessageService } from "primeng/api";
import { ProductInformationComponent } from "../../../e-commerce-web/src/app/components/products/product-information/product-information.component";
import { ProductCardComponent } from "../../../e-commerce-web/src/app/components/products/product-card/product-card.component";
import { ProductSpecificationsComponent } from "@web/app/components/products/product-specifications/product-specifications.component";
import { ChangeComponent } from "@web/app/components/products/change/change.component";
import { NewLicenseComponent } from "@web/app/components/products/new-license/new-license.component";
import { RenovacionesComponent } from "@web/app/components/products/renovaciones/renovaciones.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
  declarations: [
    ProductInformationComponent,
    ProductCardComponent,
    ProductSpecificationsComponent,
    ChangeComponent,
    NewLicenseComponent,
    RenovacionesComponent,
  ],
  imports: [
    CommonModule,
    ControlsModule,
    ErrorPageModule,
    ProductsModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
  exports: [
    BrowserModule,
    CommonModule,
    ControlsModule,
    TranslateModule,
    PipesModule
  ],
  providers:[
   MessageService
  ]
})
export class ECommerceSithecCommonModule {}